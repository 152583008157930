import { observer } from 'mobx-react';
import { just } from 'maybeasy';
import {
  Accordion,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
} from '@execonline-inc/execonline-ui';
import { whenGt } from '@execonline-inc/numbers';
import { ExperienceFiltersResource } from '../../../../Common/Experience/Types';
import { MenuFilter } from '../CommonFilter';
import CompetencyFilterStore from '../CompetencyFilter/Store';
import { allCompetencyOptions } from '../CompetencyFilter/Types';
import DurationFilterStore from '../DurationFilter/Store';
import { allDurationOptions } from '../DurationFilter/Types';
import LanguageFilterStore from '../LanguageFilter/Store';
import { allLanguageOptions } from '../LanguageFilter/Types';
import SchoolPartnerFilterStore from '../SchoolPartnerFilter/Store';
import { allSchoolPartnerOptions } from '../SchoolPartnerFilter/Types';
import FiltersButton from './FiltersButton';
import SidebarFooter from './SidebarFooter';
import SidebarHeader from './SidebarHeader';

export interface Props {
  languageFilterStore: LanguageFilterStore;
  durationFilterStore: DurationFilterStore;
  schoolPartnerFilterStore: SchoolPartnerFilterStore;
  competencyFilterStore: CompetencyFilterStore;
  options: ExperienceFiltersResource;
}

function SidebarFilters({
  languageFilterStore,
  durationFilterStore,
  schoolPartnerFilterStore,
  competencyFilterStore,
  options,
}: Props) {
  function handleClearAll() {
    competencyFilterStore.clearSelectedValues();
    durationFilterStore.clearSelectedValues();
    schoolPartnerFilterStore.clearSelectedValues();
    languageFilterStore.clearSelectedValues();
  }

  function handleApply() {
    competencyFilterStore.applySelectedValues();
    durationFilterStore.applySelectedValues();
    schoolPartnerFilterStore.applySelectedValues();
    languageFilterStore.applySelectedValues();
  }

  function getAppliedFiltersCount() {
    return just(
      competencyFilterStore.appliedCount +
        durationFilterStore.appliedCount +
        schoolPartnerFilterStore.appliedCount +
        languageFilterStore.appliedCount,
    ).andThen(whenGt(0));
  }

  return (
    <>
      <FiltersButton value={getAppliedFiltersCount()} onClear={handleClearAll}>
        {({ isOpen, onClose }) => (
          <Drawer open={isOpen} onClose={onClose}>
            <DrawerHeader>
              <SidebarHeader onClose={onClose} />
            </DrawerHeader>
            <DrawerBody>
              <Accordion className="px-4">
                <MenuFilter
                  title="Competency"
                  store={competencyFilterStore}
                  filterOptions={allCompetencyOptions(options)}
                />
                <MenuFilter
                  title="Time Commitment"
                  store={durationFilterStore}
                  filterOptions={allDurationOptions(options)}
                />
                <MenuFilter
                  title="Partner"
                  store={schoolPartnerFilterStore}
                  filterOptions={allSchoolPartnerOptions(options)}
                />
                <MenuFilter
                  title="Language"
                  store={languageFilterStore}
                  filterOptions={allLanguageOptions(options)}
                />
              </Accordion>
            </DrawerBody>
            <DrawerFooter>
              <SidebarFooter onClear={handleClearAll} onApply={handleApply} />
            </DrawerFooter>
          </Drawer>
        )}
      </FiltersButton>
    </>
  );
}

export default observer(SidebarFilters);
