import { observer } from 'mobx-react';
import { CheckBox, Select, SelectMenuItem, Typography } from '@execonline-inc/execonline-ui';
import { TPlainTextKey } from '../../../../../../Translations';
import FilterPlaceholder from './FilterPlaceholder';
import CommonFilterStore from './Store';
import { FilterOption, isFilterItemSelected } from './Types';

interface Props {
  store: CommonFilterStore;
  label: TPlainTextKey;
  filterOptions: FilterOption[];
}

const handleChange =
  (store: CommonFilterStore) =>
  (value: string[] = []) => {
    store.appendSelectedValues(value);
    store.applySelectedValues();
  };

const handleClean = (store: CommonFilterStore) => () => {
  store.clearSelectedValues();
};

const renderedValue = (store: CommonFilterStore, label: TPlainTextKey) => (value: string[]) => {
  return <FilterPlaceholder selectedOptions={value} title={label} onClear={handleClean(store)} />;
};

function DropdownFilter({ filterOptions, label, store }: Props) {
  const selectedValues = [...store.state.filter.appliedValues];

  return (
    <Select
      className="w-1/4"
      multiple
      value={selectedValues}
      renderValue={renderedValue(store, label)}
      onChange={handleChange(store)}
    >
      {filterOptions.map(({ value, label }) => {
        return (
          <SelectMenuItem value={value} key={value}>
            <CheckBox size="sm" checked={isFilterItemSelected(selectedValues, value)}>
              <Typography className="whitespace-normal text-left text-black" variant="bodyMedium">
                {label}
              </Typography>
            </CheckBox>
          </SelectMenuItem>
        );
      })}
    </Select>
  );
}

export default observer(DropdownFilter);
