import { Maybe, just } from 'maybeasy';
import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';
import { T } from '../../../../../../../../Translations';
import { ExperienceSectionTitle } from '../../../../../Common/ExperienceSection';
import { useExperiencesContext } from '../../../ExperiencesContext';
import GoBackButton from '../ExperienceActions/GoBackButton';

interface Props {
  searchValue: Maybe<string>;
}

function ExperienceSectionTitleWithSearch({ searchValue, children }: PropsWithChildren<Props>) {
  const experienceStore = useExperiencesContext();

  return just({})
    .assign('search', searchValue)
    .assign('useCase', experienceStore.useCase)
    .map(({ useCase, search }) => (
      <div className="grid flex-col gap-y-4">
        <ExperienceSectionTitle variant="h5">
          <T
            kind={`Search Results for "{{term}}" in {{useCase}}`}
            term={search}
            useCase={useCase.name.text}
          />
        </ExperienceSectionTitle>
        <GoBackButton />
      </div>
    ))
    .getOrElse(() => <ExperienceSectionTitle>{children}</ExperienceSectionTitle>);
}

export default observer(ExperienceSectionTitleWithSearch);
