import { observer } from 'mobx-react';
import { CirclePlaySolidIcon, Image, cn } from '@execonline-inc/execonline-ui';
import { handleEnterKeyDown, handleMouseDown } from '../Types';

interface Props {
  className?: string;
  src: string;
  onClick: () => void;
}
function ExperienceVideoThumbnail({ className, src, onClick }: Props) {
  return (
    <div
      tabIndex={0}
      className={cn(
        'relative z-1 flex h-full w-full cursor-pointer items-center overflow-hidden bg-[#e1e1e1]',
        className,
      )}
      onClick={handleMouseDown(onClick)}
      onKeyDown={handleEnterKeyDown(onClick)}
    >
      <Image src={src} />
      <CirclePlaySolidIcon
        color="white"
        className="pointer-events-none absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        size="2xl"
      />
    </div>
  );
}
export default observer(ExperienceVideoThumbnail);
