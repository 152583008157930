import { computed, observable } from 'mobx';
import { equalArrays } from '../../../../../Collections';
import { ExperienceFilters, ExperienceResource } from '../../../Common/Experience/Types';
import CommonFilterStore from './CommonFilter/Store';
import {
  Filter,
  State,
  allBlank,
  whenAnyMatchingAvailableLanguages,
  whenAnyMatchingCompetency,
  whenAnyMatchingDuration,
  whenAnyMatchingSchoolPartner,
} from './Types';

class FilterStore {
  @observable
  state: State;

  readonly initialFilterValues: Filter;

  constructor(filters: ExperienceFilters) {
    this.initialFilterValues = {
      availableLanguages: filters.availableLanguages.map((l) => l.payload.code),
      durations: filters.durations.map((d) => d.payload.value),
      schoolPartners: filters.schoolPartners.map((sp) => sp.payload.name),
      competencies: filters.competencies.map((c) => c.payload.name),
    };

    this.state = allBlank(this.initialFilterValues);
  }

  @computed
  get allFilterValues() {
    switch (this.state.kind) {
      case 'waiting':
      case 'loading':
      case 'updating':
      case 'error':
        return this.state;
    }
  }

  allFilterOptionsSelected<T>(selected: ReadonlyArray<T>, all: ReadonlyArray<T>): boolean {
    return equalArrays(selected, all);
  }

  showFilteredExperience = (
    experience: ExperienceResource,
    languageFilter: CommonFilterStore,
    durationFilter: CommonFilterStore,
    schoolPartnerFilter: CommonFilterStore,
    competencyFilter: CommonFilterStore,
  ): boolean => {
    return (
      whenAnyMatchingAvailableLanguages(experience, languageFilter) &&
      whenAnyMatchingDuration(experience, durationFilter) &&
      whenAnyMatchingSchoolPartner(experience, schoolPartnerFilter) &&
      whenAnyMatchingCompetency(experience, competencyFilter)
    );
  };
}

export default FilterStore;
