import * as React from 'react';
import { observer } from 'mobx-react';
import { assertNever } from '@kofno/piper';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { PreviewModeContext } from '../../PreviewMode/PreviewModeContext';
import ExperiencesStore from '../Experiences/ExperiencesStore';
import NavigationBar from '../../../../../components/Platform/NavigationBar';

interface Props {
  experiencesStore: ExperiencesStore;
}

const Header: React.FC<Props> = ({ experiencesStore }) => {
  return (
    <>
      <div className="bg-white sm:px-0">
        <PreviewModeContext.Consumer>
          {(previewMode) => {
            switch (previewMode) {
              case 'preview':
                return emptyFragment();
              case 'default':
                return <NavigationBar className="mx-auto box-border xl:container" />;
              default:
                assertNever(previewMode);
            }
          }}
        </PreviewModeContext.Consumer>
      </div>
    </>
  );
};

export default observer(Header);
