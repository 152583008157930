import { emptyFragment, ModalBody, ModalFooter, ModalHeader } from '@execonline-inc/execonline-ui';
import { useConst } from '@execonline-inc/react-hooks.private';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import ContentStates from '../../../../../../components/ActiveProgramOverview/ActiveCoachingProductOverview/Orientation/OrientationModal/ModalSegment/ContentStates';
import PreviousSegmentButton from '../../../../../../components/AutoLaunchableModal/ModalSegment/PreviousSegmentButton';
import SubmitAndAdvanceButton from '../../../../../../components/Segment/SegmentNavigation/NextAdvanceButton/SubmitAndAdvanceButton';
import SegmentReactions from '../../../../../../components/SegmentReactions';
import { findLinkBy } from '../../../../../../Links';
import { ProgramModuleProgress } from '../../../../../../ProgramModuleProgress';
import { ProgramModuleProgressProvider } from '../../../../../../ProgramModuleProgress/Context';
import SegmentStore from '../../../../../../SegmentStore';
import { Segment, SegmentResource } from '../../../../../../SegmentStore/Types';
import { T } from '../../../../../../Translations';
import UpdateFocusModalHeader from './UpdateFocusModalHeader';

interface Props {
  segment: Segment;
  onClose: () => void;
}

function UpdateFocusModalView({ segment, onClose }: Props) {
  const { id, programTitle, moduleId, programId } = segment;

  const segmentStore = useConst(() => new SegmentStore('Streamlined'));

  return (
    <ProgramModuleProgressProvider store={segmentStore}>
      <ModalHeader className="flex-col pb-0 sm:pb-0 sm:pt-10">
        <UpdateFocusModalHeader>{programTitle}</UpdateFocusModalHeader>
      </ModalHeader>
      <ModalBody className="min-h-115">
        <ProgramModuleProgress />
        <ContentStates store={segmentStore} />
      </ModalBody>
      <ModalFooter className="flex-row justify-between sm:pb-9">
        {findLinkBy({ rel: 'previous' }, segmentStore.links)
          .map(() => (
            <PreviousSegmentButton data-testid="previous-segment-button" store={segmentStore}>
              <T kind="Previous" />
            </PreviousSegmentButton>
          ))
          .getOrElse(emptyFragment)}
        <SubmitAndAdvanceButton
          data-testid="next-segment-button"
          className="ml-auto"
          store={segmentStore}
        >
          {findLinkBy({ rel: 'next' }, segmentStore.links)
            .map(() => <T kind="Next" />)
            .getOrElse(() => (
              <T kind="Finish" />
            ))}
        </SubmitAndAdvanceButton>
      </ModalFooter>
      <SegmentReactions
        params={{
          programId: String(programId),
          moduleId: String(moduleId),
          segmentId: String(id),
        }}
        store={segmentStore}
        advanceHook={(nextSegment: Maybe<SegmentResource>) => {
          nextSegment.elseDo(onClose);
        }}
        fireImmediately
      />
    </ProgramModuleProgressProvider>
  );
}

export default observer(UpdateFocusModalView);
