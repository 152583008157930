import Decoder, { field, nullable, number, string, succeed } from 'jsonous';
import { resourceDecoder } from '../Resource/Decoders';
import { Organization, OrganizationResource } from './Types';

const organizationDecoder: Decoder<Organization> = succeed({})
  .assign('id', field('id', number))
  .assign('accountUid', field('account_uid', nullable(string)))
  .assign('name', field('name', string));

export const organizationResourceDecoder: Decoder<OrganizationResource> =
  resourceDecoder(organizationDecoder);
