import { observer } from 'mobx-react';
import { Typography, IconButton, CloseIcon } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../Translations';

interface Props {
  onClose: () => void;
}

function SidebarHeader({ onClose }: Props) {
  return (
    <>
      <Typography fontWeight="medium" as="h5">
        <T kind="Filters" />
      </Typography>
      <IconButton className="absolute right-6 top-1/2 -translate-y-1/2" onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </>
  );
}

export default observer(SidebarHeader);
