import { observer } from 'mobx-react';
import SkeletonCarouselExperiences from './SkeletonCarouselExperiences';
import SkeletonExperiences from './SkeletonExperiences';

function SkeletonProductCollections() {
  return (
    <div className="flex flex-1 flex-col gap-y-8">
      <SkeletonCarouselExperiences />
      <SkeletonExperiences />
    </div>
  );
}

export default observer(SkeletonProductCollections);
