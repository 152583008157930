import { ButtonProps, emptyFragment } from '@execonline-inc/execonline-ui';
import { whenGt } from '@execonline-inc/numbers';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';
import { useProgramModuleProgressContext } from '../../../../../ProgramModuleProgress';
import SegmentStore from '../../../../../SegmentStore';
import { AnyAdvancer } from '../../../../../SegmentStore/Types';
import { AdvanceButton } from '../../Common';

interface Props extends ButtonProps {
  advancer: AnyAdvancer;
  store: SegmentStore;
}

function ReadyButton({ advancer, store, ...others }: PropsWithChildren<Props>) {
  const progress = useProgramModuleProgressContext();

  switch (advancer.kind) {
    case 'advancer':
    case 'complete-and-advancer':
    case 'submit-and-advancer':
      return progress
        .andThen((progress) => just(progress.payload.currentPosition))
        .andThen(whenGt(1))
        .map(() => <AdvanceButton {...others} onClick={store.previousAdvance} />)
        .getOrElse(emptyFragment);
    case 'disabled-advancer':
      return <AdvanceButton {...others} disabled />;
    case 'no-advancer':
      return emptyFragment();
  }
}

export default observer(ReadyButton);
