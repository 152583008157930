import { readVarM } from '@execonline-inc/environment';
import {
  Config,
  FetchError,
  decodedBy,
  initialize,
  toHeaders,
} from '@execonline-inc/fetch.private';
import { warn } from '@execonline-inc/logging';
import { always } from '@kofno/piper';
import { just } from 'maybeasy';
import { Task } from 'taskarian';
import Links from '../Links/Init';
import { Link, type Rel } from '../Resource/Types';
import type { Endpoint } from '../Resourceable';
import { rootResourceStore } from '../RootResourceStore';
import { sessionResourceDecoder } from '../Session/Reactions';
import { sessionStore } from '../Session/Store';

export default initialize<Rel, Endpoint>(Links, {
  headers: () =>
    toHeaders([
      ['Accept', just('application/json')],
      ['Application-Id', readVarM('REACT_APP_APPLICATION_ID')],
      ['Api-Compatibility', readVarM('REACT_APP_API_COMPATIBILITY')],
      ['Authorization', sessionStore.sessionToken.map((token) => `Bearer ${token}`)],
    ]),
  toRetry: ({ error, fetch, ...originalRequest }) => {
    const { options } = originalRequest;
    const config: Config = { ...originalRequest.config, toRetry: always(Task.fail(error)) };

    const renewSession = (link: Link) =>
      Task.succeed<FetchError, {}>({})
        .andThen(() => fetch({ href: link.href, method: link.method, options, config }))
        .andThen(decodedBy(sessionResourceDecoder))
        .map(({ data }) => data.payload)
        .do(sessionStore.present);

    const tryRenewSession = () =>
      Links.findLinkByR(
        { rel: 'app-session', type: 'application/json' },
        rootResourceStore.links,
      ).cata({
        Ok: (link) => renewSession(link),
        Err: ({ rel }) => {
          warn(`The link for session renewal is missing from the root resource (${rel})`);
          return Task.fail(error);
        },
      });

    switch (error.kind) {
      case 'decoder-error':
      case 'network-error':
        return Task.fail(error);
      case 'http-error':
        return error.response.response.status === 401
          ? tryRenewSession().andThen(() => fetch({ ...originalRequest, config }))
          : Task.fail(error);
    }
  },
});
