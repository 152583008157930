import { observer } from 'mobx-react';
import * as React from 'react';
import Footer from '../../../../components/Footer';
import { localPublicUrl } from '../../../MobileImageBackground/Types';
import EnrollmentStore from '../Enrollment/Store';
import { PreviewModeContext } from '../PreviewMode/PreviewModeContext';
import ExperienceSelectionStatus from './ExperienceSelectionStatus';
import ExperiencesStore from './Experiences/ExperiencesStore';
import Header from './Header';

interface Props {
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
  experiencesStore: ExperiencesStore;
}

export function getBackgroundImage(bgImage?: string): React.CSSProperties {
  return {
    backgroundImage: bgImage ? `url("${localPublicUrl(bgImage)}")` : 'bg-chevrons.svg',
    backgroundPositionY: '-80px',
  };
}

const ExperienceSelection: React.FC<Props> = ({
  invitationUuid,
  experiencesStore,
  enrollmentStore,
}) => {
  return (
    <div
      style={getBackgroundImage()}
      className="bg-chevrons flex flex-grow flex-col bg-[length:100%] bg-repeat-y"
    >
      <Header experiencesStore={experiencesStore} />
      <ExperienceSelectionStatus
        enrollmentStore={enrollmentStore}
        experiencesStore={experiencesStore}
        invitationUuid={invitationUuid}
      />
      <PreviewModeContext.Consumer>
        {(previewMode) => {
          switch (previewMode) {
            case 'default':
              return <Footer />;
            case 'preview':
              return <></>;
          }
        }}
      </PreviewModeContext.Consumer>
    </div>
  );
};

export default observer(ExperienceSelection);
