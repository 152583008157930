import React from 'react';
import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { discoveryStore } from '../../../../../DiscoveryStore';
import { DiscoveryStateProps } from './Types';

interface DiscoveryStateProviderProps {
  children: (props: DiscoveryStateProps) => React.ReactElement;
}

function DiscoveryStateProvider({ children }: DiscoveryStateProviderProps) {
  const state = discoveryStore.state;
  console.log('Discovery State: ', state.kind);
  switch (state.kind) {
    case 'ready':
    case 'refreshing': {
      const { learningCollections } = state.resource.payload;
      return learningCollections.map((items) => children({ items })).getOrElse(emptyFragment);
    }
    case 'waiting':
    case 'loading':
    case 'errored':
      return emptyFragment();
  }
}

export default observer(DiscoveryStateProvider);
