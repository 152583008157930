import { Skeleton } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { SkeletonListViewProgramExperience } from '../../../../../Common/ProgramExperience';

function SkeletonSearchExperiences() {
  return (
    <div className="mx-auto flex w-full flex-col px-0 xl:container sm:py-8">
      <div className="flex flex-col gap-4 px-6 md:px-16">
        <Skeleton className="w-full md:w-10/12" size="xl" />
        <Skeleton className="w-3/12 md:w-2/12 lg:w-1/12" variant="rectangle" size="md" />
      </div>
      <div className="mt-6 flex flex-col gap-4 px-6 sm:mt-14 md:px-16">
        {Array.from({ length: 3 }, (_, index) => (
          <SkeletonListViewProgramExperience key={index} />
        ))}
      </div>
    </div>
  );
}

export default observer(SkeletonSearchExperiences);
