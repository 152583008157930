import { observer } from 'mobx-react';
import { Typography } from '../../../../components/UI';
import { T } from '../../../../Translations';
import EnrollmentStore from '../Enrollment/Store';
import ExperiencesStore from './Experiences/ExperiencesStore';
import SkeletonProductCollections from './Experiences/ProductCollectionsAndExperiences/SkeletonProductCollections';
import ExperiencesWithFilters from './ExperiencesWithFilters';
import SkeletonFilterSection from './Filters/SkeletonFilterSection';

interface Props {
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
  experiencesStore: ExperiencesStore;
}

function ExperienceSelectionStatus({ invitationUuid, enrollmentStore, experiencesStore }: Props) {
  switch (experiencesStore.state.kind) {
    case 'waiting':
    case 'loading':
      return (
        <>
          <SkeletonFilterSection />
          <SkeletonProductCollections />
        </>
      );
    case 'product-collections':
    case 'experiences':
    case 'product-collections-and-experiences':
    case 'searching':
      const { experienceFilters } = experiencesStore.state.resource.payload;

      return (
        <ExperiencesWithFilters
          invitationUuid={invitationUuid}
          experienceFilters={experienceFilters}
          enrollmentStore={enrollmentStore}
          experiencesStore={experiencesStore}
        />
      );
    case 'error':
      return (
        <div className="flex flex-grow flex-col items-center justify-center">
          <Typography className="leading-normal">
            <T kind="Your request failed." />
          </Typography>
        </div>
      );
  }
}

export default observer(ExperienceSelectionStatus);
