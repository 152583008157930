import { find } from '@execonline-inc/collections';
import {
  defaultSettings,
  element,
  initTask,
  interpolator,
  Interpolator,
  Loaded,
  LoadedFromFallback,
  LocalizationFormat,
  Localizeable,
  parameterized as p,
  Parameterized,
  Props,
  scalar,
  translations,
  TranslationsState,
} from '@execonline-inc/translations';
import { localizer } from '@execonline-inc/translations/lib/localizations';
import i18next, * as i18n from '@prebsch-exo/i18next';
import LanguageDetector from '@prebsch-exo/i18next-browser-languagedetector';
import HttpApi from '@prebsch-exo/i18next-http-backend';
import { Maybe } from 'maybeasy';
import * as React from 'react';
import { identity } from 'rxjs';
import { Task } from 'taskarian';
import { equalTo } from '../Collections/Predicates';
import { SupportedLanguageCode } from '../SupportedLanguages/Types';

const languageDetectionOptions = {
  order: ['localStorage', 'navigator', 'querystring'],
};

const defaultI18nSettings: i18n.InitOptions = {
  ...defaultSettings,
  detection: languageDetectionOptions,
  backend: {
    loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
  },
};

interface I18nextInitialization {
  initializer: i18n.i18n;
  i18nSettings: i18n.InitOptions;
}

const initialization = (initializer: i18n.i18n): I18nextInitialization => ({
  initializer,
  i18nSettings: defaultI18nSettings,
});

const i18nInitializerT = (fallback: SupportedLanguageCode): Task<never, I18nextInitialization> => {
  const initializer = i18next.use(HttpApi);

  return Task.succeed<never, i18n.i18n>(initializer.use(LanguageDetector)).map(initialization);
};

export const loader = (fallback: SupportedLanguageCode): Task<LoadedFromFallback, Loaded> =>
  i18nInitializerT(fallback)
    .mapError<LoadedFromFallback>(identity)
    .andThen(({ initializer, i18nSettings }) => initTask(initializer, i18nSettings));

const translatablePlainTextKeys = [
  'Your learning journey',
  'Your payment cannot be confirmed at this time. Please contact support@execonline.com for assistance.',
  'There was a problem loading the payment form. We are investigating. Please try again later.',
  'Confirm And Pay',
  'Your payment could not be confirmed due to a service interruption. Please contact support@execonline.com for assistance.',
  'We are experiencing an issue',
  'Your payment was successful but there is a problem with your enrollment. Please contact support@execonline.com for assistance.',
  'Your payment was declined by the credit card provider. Please go back to the previous page and try submitting payment again using a different credit card, or contact support@execonline.com for assistance.',
  'Unable to fulfill load payment form',
  'We could not process your payment',
  'Document was rejected by the server. Wrong format.',
  'You need to be authenticated to perform that operation',
  'You are not permitted to perform that operation',
  'You are not a member of this team.',
  'Load all results',
  'Select One',
  'Program Name',
  'Team Name',
  'Search',
  'No team found',
  'Search for a team',
  'Please search or select a program / team name',
  'No team members',
  'Teams',
  'View Team Member List',
  'Launch Team Video Meeting',
  'Click to resubscribe to this thread and receive notifications',
  'You are currently subscribed to this thread. Click to unsubscribe',
  'You will receive an email with instructions about how to reset your password in a few minutes.',
  'Error resetting password',
  'You are missing the application id.',
  'You are missing the API compatibility version',
  'Unable to reset password',
  'Select a region',
  'Add your first name',
  'Add your last name',
  'Default Landing Page',
  'This experience is not available in this language.',
  'Not available in language',
  'Confirm Your Enrollment',
  'Complete your purchase',
  'Specific Date',
  'Currently enrolled.',
  'On Demand',
  'Start Now',
  'Embedded Conference Room',
  'Enroll',
  'Next Available',
  'Enroll Now',
  'Loading...',
  'Saving...',
  'Error Loading Program',
  'Explore',
  'Learning Collections',
  'Go To Discovery Portal',
  'All Experiences',
  'Browse Experiences',
  'Browse All Experiences',
  'No matching experiences found',
  'Choose Now',
  'Error Loading Payment Form',
  'Some options may not be available due to conflicts with other enrollments.',
  'price',
  'For paid programs you will need a valid credit card in the next step.',
  'This option conflicts with an existing enrollment. Choose another option.',
  'You are currently enrolled in an On Demand experience. Please ...',
  'You are already enrolled in this experience.',
  'Find the next experience to add ...',
  'More Experiences Available',
  'Stay On Track',
  'No events on this day',
  'You’re ahead of schedule: You’re on',
  'and you were scheduled to finish',
  'Now',
  'Date',
  'Starts',
  'Ends',
  'Join Meeting',
  'Join Team Conference Room',
  'The link for this meeting will be available shortly before the start time.',
  'Current',
  'Team',
  'Chat',
  'Support',
  'Events',
  'Resources',
  'Teams',
  'Discussions',
  'Dashboard',
  'Exit',
  'Send a message...',
  'Sitemap',
  'Go to the dashboard',
  'Next Segment',
  'Next Step',
  'lorum ipsum dolor',
  'View more dates',
  'This is just a placeholder for the future login page',
  "Email can't be blank",
  '{{count}} second until your token expires',
  'Your Programs',
  'add a note',
  'Outline',
  'Notes in this segment',
  'Add Segment Note',
  "What's Next",
  'Program Outline',
  'End Date',
  'Progress',
  'Save',
  'Edit',
  'Delete',
  'Sessions Completed',
  'Email Address',
  'Facebook Profile',
  'LinkedIn Profile',
  'Twitter Profile',
  'Password',
  'Learn on the go with our mobile app',
  'loading content',
  'Login to Your Account',
  'Login failed',
  'Unrecognized email',
  'Login',
  'Log out',
  'Attempting to sign in to your corporate system. Please be patient.',
  'The server responded with unexpected data.',
  'Goodbye!',
  'Notes',
  'My Notes',
  'Segments',
  'Previous Segment',
  'Back to Overview',
  'Download Slides',
  'Program',
  'Start Date',
  'View All Program Notes',
  'show more',
  'Show Slides',
  'Hide Slides',
  'Segments must be completed in order. Please complete the segment above first.',
  'Continue',
  'Completed',
  'Expired',
  'Not Started',
  'Your session has expired. Please login.',
  'Overview',
  'Experience',
  'Current Program',
  'Terms and Conditions',
  'Edit Profile',
  'File not available. The upload is still being processed.',
  'Select a file to submit',
  'Select Program',
  'Title',
  'Submit Team Discussion',
  'This segment cannot be completed at this time.',
  'You cannot perform this operation at this time.',
  'Events Happening Now',
  'Upcoming Events',
  'Pre-Program Module Start',
  'Program Start',
  'Your request failed',
  'Upload Your Assignment',
  'Files to be Submitted',
  'Submitted Assignment',
  'Upload and Submit Assignment',
  'Attachments',
  '0 Attachments',
  'Post to Team Discussion Area',
  'Content',
  'Add an Attachment (optional)',
  'Files in this resource',
  'Video asset is not available',
  'You have access to this program starting',
  '(mobile)',
  '(work)',
  'Reset Your Password',
  'Registration is processing. The meeting link will be available here shortly.',
  "We're sorry, but we couldn't find what you were looking for.",
  'Need Assistance?',
  'Back to previous page',
  'Next',
  'Go Back',
  'Group Chat',
  'My Profile',
  'Participants',
  'Agenda',
  'Team Members',
  'Confirm Reset Password',
  'Email address is required',
  'Please provide a valid email address',
  'Logging Out Soon Due to Inactivity',
  'No conference room could be located.',
  'Embedded conference room is disabled.',
  'Please remember team files are confidential ...',
  'Calendar',
  'Dial-in information: +1 (888) 240-2560, meeting ID',
  'Could not identify a program for this team',
  'Meeting Actions',
  'Return to Main Room',
  'Go To Breakout Room',
  'Team Files',
  'Deliverable file(s) submitted',
  'Request a Coach',
  'Try again.',
  'Your coach request could not be sent.',
  'Raise Hand',
  'Lower Hand',
  'Not Yet Arrived',
  'Dial-in instructions for this meeting',
  'You may call into the meeting using the details below:',
  'Toll-free number:',
  'Number:',
  'Dial-in support is not yet available for this meeting',
  'Meeting ID:',
  'international numbers',
  'View Dial-in Instructions',
  'Your request has been sent. A coach will be arriving soon!',
  'Announcements',
  'Message Your Team',
  'Your Live Meeting is Starting!',
  'You have no current meetings',
  'Program Completed',
  'Program Access Until',
  'You may gain access to your learning experience starting',
  'Upcoming',
  'You are not currently enrolled in any programs.',
  'Conference roster is not available',
  'Unable to update conference roster',
  'See More...',
  'This file is processing',
  'We were unable to load this lecture. Please try again later.',
  'Team Meeting Room',
  'troubleshooting',
  'Restart Meeting',
  'Troubleshooting Meeting Issues',
  'Still having problems?',
  'view announcement',
  'Have a question or need assistance?',
  'Go to the Support Desk',
  'Load Older Announcements',
  'ExecOnline Coach',
  'Please confirm you want to delete this message',
  'Cancel',
  'Please confirm you want to report this message',
  'Report',
  'We have received your report',
  'Report this message',
  'Delete this message',
  'Not specified',
  'None',
  'Name',
  'Organization',
  'Division',
  'Position',
  'Company',
  'Location',
  'Country',
  'Region',
  'Time Zone',
  'Preferred Language',
  'Email',
  'React to this message',
  'Reply to this message',
  'Get started on your program',
  'Continue your program where you left off',
  'Team Messages',
  'View Messages',
  'Choose a Day and Time',
  'Schedule Later - Go To Next Segment',
  'Program Not Ready',
  'Needed For Assignments',
  'Uploaded By You',
  'Shared By A Learning Partner',
  'Personalized To You',
  'By Programs',
  'Live Meeting Recordings',
  'Search or browse for resources',
  'Browse by category',
  'Important Resources',
  'View Resources',
  'No programs found.',
  'No resources to display',
  "Demo Refresh Didn't Load",
  'Demo reset waiting for background processes to finish',
  'Resetting Demo',
  'reset demo',
  'Broadcast Message to All Rooms',
  'Broadcast Message',
  'Immediately send a message to all breakout rooms',
  'Broadcast a message to everyone...',
  'Send Message',
  'Team not found',
  'Enter a new password for your account.',
  'Be sure to choose a secure password that only you will know.',
  'Please provide the full URL if you are coming from a reset password email',
  'Sign In',
  'Events Calendar',
  'Team Events Calendar',
  'All rights reserved',
  'Team Messaging',
  'Within This Team',
  'An error occurred, please refresh the page and try again',
  'Current topic:',
  'Send a message to your team...',
  'Type your reply...',
  'Previous',
  'Program Participants',
  'Support Staff',
  'Please choose the meeting you would like to attend',
  'Click the button below to schedule your feedback session:',
  'Your session has expired, please log in again.',
  'I forgot my password',
  'show less',
  'URL is not valid',
  'Your request took too long to complete',
  'Server cannot be reached',
  'Scroll to newest messages...',
  'Scroll to New messages',
  'Realtime connection not available.',
  'Loading More Messages',
  "You don't belong to any teams.",
  'Thank you!',
  'This segment has been completed.',
  'Webinar segments are not supported in P3.',
  'Meeting segments are not supported in P3.',
  'Start Lecture',
  'PROGRAM-WIDE RESOURCES',
  'Keeping Pace with Your Cohort',
  'The cohort has reached this ExecOnline-recommended ...',
  'Of course, professional and other obligations ...',
  'Due date for upcoming modules',
  'Live Event Dashboard',
  'Oops! We were unable to find your file.',
  'Download File',
  'View File',
  'View in Browser',
  'Download Notes',
  'downloading...',
  'Download or View File',
  'Your Live Meeting is starting soon!',
  'Your Live Meeting has started!',
  'Dismiss',
  'Segments must be completed in order. Please ...',
  'View Events Calendar',
  'Close',
  'If you need to cancel or modify the time of the session, ...',
  'Live Project Feedback is an added experience that your ...',
  'Your meeting was successfully scheduled',
  'Schedule Later',
  'Modules',
  'My private uploads',
  'Your Cohort',
  'Please fix the following errors:',
  'Your file is loading...',
  'Your meeting is loading',
  'Locating document...',
  'uploading file',
  'You do not currently have any notes',
  'You do not currently have any resources',
  'Your Resources page will contain videos, presentations, and ...',
  'You are currently not on a team',
  'Earn certificates from top business schools. Enroll with ExecOnline.',
  'You are not currently enrolled in any Experiences. Browse ...',
  'The ExecOnline team looks forward to hosting you in ...',
  'Your Team page will include a listing of participants on your ...',
  'When',
  'Account',
  'Note: This photo will be visible to other ExecOnline participants.',
  'Are you sure you want to delete this note?',
  'Are you sure you want to exit registration?',
  'Confirm Password',
  'Submit Profile',
  'remove photo',
  'Set New Password',
  'Your team is talking! Join the discussion!',
  'Current Events',
  'Adding a photo enhances your ExecOnline experience ...',
  'Work',
  'Social',
  'Personal',
  'Photo',
  'Current Position',
  'Location (State And Country)',
  'LinkedIn URL',
  'Facebook URL',
  'Twitter URL',
  'First Name',
  'Last Name',
  'Cell Phone',
  'Work Phone',
  'Extension',
  'Profile Preview',
  'Time',
  'Duration',
  'Please view the invite sent to your calendar for full details',
  'During your program, you will have the ability ...',
  'Contact Support',
  'Upload a photo to complete your profile',
  'Upload Photo',
  'Document downloading, please check your browser downloads for progress.',
  'Go To Event Dashboard',
  'Send a message to the group...',
  'password confirmation',
  'Ex: California, US',
  'Ex: Technology',
  'Ex: Director',
  'less than a minute from now',
  'less than a minute ago',
  'a minute from now',
  'a minute ago',
  'about an hour from now',
  'about an hour ago',
  'a day from now',
  'a day ago',
  'about a month from now',
  'about a month ago',
  'about a year from now',
  'about a year ago',
  'STEP 1: ENTER YOUR CUSTOMER CHARACTERISTICS QUESTIONS',
  'What customer characteristics would you like ...',
  'Please enter your four (4) questions and descriptions.',
  'Customer Characteristics',
  'STEP 2: DESCRIBE YOUR PRODUCT OR SERVICE',
  'Which product or service will you ask ...',
  'Product/Service Description',
  'STEP 3: ENTER PRODUCT OR SERVICE ATTRIBUTES',
  'Which attributes do you want customers to consider? ...',
  'Description',
  'Question 1',
  'Question 2',
  'Question 3',
  'Question 4',
  'e.g. What is your age?',
  'e.g. 20-29, 30-39, 40-49, 50-59, 60+',
  'e.g. What is your location?',
  'e.g. New York, Boston, Chicago, Miami, San Francisco',
  'e.g. What is your gender?',
  'e.g. Male, Female',
  'e.g. What is your role?',
  'e.g. Staff, Management, Executive',
  'e.g. Location',
  'e.g. Uptown',
  'e.g. Downtown',
  'e.g. Uptown = north of 35th St; Downtown = south of 35th St.',
  'e.g. Size',
  'e.g. Efficiency',
  'e.g. One bedroom',
  'e.g. Efficiency = ~500 sq feet; One bedroom= ~800 sq fee',
  'e.g. Finish',
  'e.g. Basic',
  'e.g. Luxury',
  'e.g. Basic = No furniture or air ...',
  'e.g. Rent',
  'e.g. $800 per month',
  'e.g. $1,600 per month',
  'e.g. Price refers to cost per unit',
  'e.g. Shopping for a new apartment',
  'Option 1',
  'Option 2',
  'Attribute',
  'Attribute/Options Description',
  'View Team Profile',
  'STEP 4: SUBMIT YOUR DESIGN',
  'When you are satisfied, click the ...',
  'SUBMIT AND CONTINUE',
  'The following people have completed your survey.',
  'At this time, no survey responses have been received.',
  'You may want to contact the customers you invited to ...',
  'No survey was created in the previous module. If you ...',
  'Consider following up with the customers to whom ...',
  'You can return to this segment as often as necessary ...',
  `If you choose to wait to run your analysis, feel free ...`,
  `The table below has one row for each completed survey ...`,
  'Run Analysis',
  'Download Results as .XLSX',
  'Main Room',
  'Join Main Room',
  'Send a message to this room...',
  'Join',
  'Join Now',
  'Set Timer',
  'Sorry, photo uploading is currently unavailable',
  'add a photo',
  'Stay Logged In',
  'update my password',
  'Commentary',
  'Accept',
  'To ensure a positive experience for all ...',
  'Upcoming Program',
  'Impact Accelerator',
  'Maximize your impact through this truly exclusive ...',
  'Learn more about Impact Accelerator',
  'Send',
  'This conference was automatically closed ...',
  'This lecture segment is still a work in progress.',
  'Click the “Choose Day and Time” button ...',
  'If you need to modify or cancel the time of the session, we ...',
  'Please note, you may come back to this segment ...',
  'Coaching',
  'Schedule Your Coaching Session',
  'Use our secure and convenient portal to find available ...',
  'Schedule Your Session Now',
  'Schedule a Session',
  'Visit our Support Desk, where you can search ...',
  'Live Meeting Terms and Privacy Policy',
  'This session may be recorded and maintained on the ExecOnline ...',
  'Choose which poll to launch',
  'Launching a poll will activate ...',
  'Launch Poll',
  'Select One...',
  'Poll Preview',
  'Close Poll',
  "Looks like your browser doesn't support ...",
  'Open Poll',
  'Live Project Feedback',
  'Schedule Your Live Feedback Session',
  'Use our secure and convenient portal to find available times ...',
  'Confirm',
  'Expected progress',
  'Access Ends',
  'Reminder: experience is expiring soon',
  'Community Group',
  'Search for a community group',
  'View Members List',
  'Launch Video Meeting',
  'Send a message...',
  'Your community is talking! Join the discussion!',
  'Member List',
  'Members',
  'Learn More',
  'Week',
  'At a Glance',
  'Learning Journey',
  'Included Programs',
  'Who Should Attend',
  'Time Commitment',
  'Certificate',
  'Certificate of Completion',
  'view sample',
  'Download Overview',
  'Key Takeaways',
  'Program Structure',
  'How It Works',
  'Skip to Content',
  'Account Navigation',
  'Account Navigation Dropdown Menu',
  'Segment Panel',
  'Previous Month',
  'Next Month',
  'Menu',
  'Exit Course Registration',
  'Complete and Advance',
  'Advance',
  'Toggle Menu',
  'Close Menu',
  'Add Note',
  'Confirm your ExecOnline registration and accept the Terms of Use to continue.',
  'Pick file',
  'Search',
  'Leadership Focus',
  'Language',
  'All',
  'School Partner',
  'Bookmarks',
  'Bookmarks Only',
  'Find the experience that meets your needs',
  'You may enroll in another experience when you finish...',
  'Have questions or need support? Contact us at support@execonline.com.',
  'Continue with SSO',
  'Get Access',
  'Please check your email for instructions ...',
  'You may now close this window.',
  'Confirm Registration',
  '{{orgName}} is working with ExecOnline to provide access ...',
  'There was an error:',
  'Go to dashboard',
  'Begin Experience',
  'Begin Your Experience',
  'Resume Experience',
  'Resume Your Experience',
  'Welcome to ExecOnline Leadership Coaching',
  "We're ready to transform your leadership. Let's get started!",
  'Return to Experience',
  'Enter your password to discover the Experiences available to you.',
  'Continue with your corporate single sign-on ...',
  'Enter your email to explore the Experiences available to you.',
  'Enter your name and accept our Terms of Use, then ...',
  'Set your ExecOnline password to ...',
  'Note options dropdown menu',
  'Required',
  'Save Profile',
  'Ex: 123', // represents an extension number
  'Dismiss dropdown notification.',
  'Enter your email address to continue.',
  'Enter your name and accept our Terms of Use, then confirm your registration to continue.',
  'Enter your password to continue.',
  'Set your ExecOnline password to continue.',
  'Contact your learning and development team for details to access ExecOnline going forward.',
  'Please select your preferred language to continue.',
  'email@domain.com',
  'Note deleted successfully',
  'Note created successfully',
  'Profile updated successfully',
  'You will receive an email with instructions about how to reset your password in a few minutes.',
  'You are missing the application id.',
  'Your request could not be completed because the url was malformed.',
  'Your request took too long to complete.',
  'You are disconnected from the server.',
  'Your request failed.',
  'You do not have access to your profile at this time.',
  'You are not able to update your profile at this time.',
  "Announcements aren't available",
  "Events aren't available",
  'Profile picture uploading is not available',
  'Your upload could not be processed at this time',
  "Password can't be blank",
  'Your experience is available now! Click the button below to get started.',
  'However, you may already have access to your team and resources.',
  'An email has been sent to you to verify your account and reset your password.',
  'Login failed; the email address or password is invalid.',
  'You are missing the application id',
  'An error occurred, please try again later',
  'We have not heard back from your corporate system. Please try again later or contact customer support.',
  'Registration Cancelled',
  'You do not have access to your conjoint analysis at this time.',
  'Messages are not available',
  'Please provide a password',
  'Please provide a password confirmation',
  'Password confirmation should match password',
  'Error resetting password',
  'Reset password token is invalid.',
  'Attachment link is unavailable',
  'Attachment ID is not present',
  'You cannot perform this operation',
  'Team ID is not present',
  'Team link is unavailable',
  'value was null or undefined',
  'Realtime features are not available at this time',
  'Websockets not supported on this platform',
  'Link not available',
  'Resource link is unavailable',
  'Resources link is unavailable',
  'Professors link in unavailable',
  'An unexpected error occurred.',
  'We are unable to proceed at this time...',
  'Missing Link',
  'You do not have permission to modify notes',
  'You cannot create a note outside of a course segment',
  'A note cannot be saved without content',
  'You do not have access to this segment yet',
  'Cannot find terms and conditions link',
  'Event link is unavailable',
  'Unable to load Freebusy',
  'Freebusy failed to schedule meeting',
  'Must accept terms of use and privacy policy',
  'Password confirmation must match password',
  'Please accept the Terms of Use and Privacy Policy in order to continue',
  'Failed to initialize modal',
  'Registration link is unavailable',
  'Unable to load the learning collection resource.',
  'Discovery portal link is unavailable',
  'There was a problem setting up payment!',
  'Learning Partners link is unavailable',
  'Continue Enrollment',
  'Currently Enrolled',
  'Email address must be provided',
  'First name must be provided',
  'Last name must be provided',
  'Terms and conditions must be accepted',
  'Failed to complete enrollment',
  'Failed to create engagement',
  'Account creation failed',
  'You are not authorized to view this page.',
  'Unable to load your experiences',
  'Unable to load your experience',
  'Unable to load the invitation resource.',
  'Could not initiate native SSO flow.',
  'Unable to find that page',
  'Unable to load this page',
  'Unable to fulfill your enrollment request',
  'Error occurred when decoding shared invitation resource error details',
  'Failed to open shared invitation link',
  'Profile updated successfully, refreshing page...',
  'Unable to update your language at this time.',
  'You successfully authenticated using a third party identity provider, but ...',
  'An error occurred, please try again later',
  'An email has been sent to you to verify your account and reset your password.',
  'You are missing the application id',
  'Registration Cancelled',
  'We have not heard back from your corporate system...',
  "Password can't be blank",
  'Login failed; the email address or password is invalid.',
  "We're sorry, but the URL you are trying to reach is invalid.",
  'User Photo',
  'Profile Photo',
  'University partner logo',
  'Event Details',
  'ExecOnline Coaching Session',
  'In this 30-minute session, you will ...',
  'Join Coaching Session',
  'Welcome to Your ExecOnline Coaching Session',
  'and others...',
  'hide replies',
  'No message content',
  'Message not available',
  'FreeBusy Requirements Check',
  "Let's do a Quick Tech Check",
  "Let's make sure you can schedule a test session and that ...",
  'Test whether your system can schedule a test session',
  'Confirm your date and time settings',
  "Check your browser's ability to run availability and scheduling features",
  '1. Schedule Test',
  'Click on Select a time and the scheduling window ...',
  'Select a time',
  '2. Date and Time Settings',
  "The date and time above should match your computer's system",
  '3. Tool Library Test',
  'Your payment is being processed. Please do not close this window.',
  'Payment is taking a bit longer than usual. Please do not close this window.',
  'Your access is ready. Click the button below to continue to get started.',
  'Scheduling library loaded',
  'Web Worker library loaded',
  'Cookies set correctly',
  'Pick-a-Time grid',
  'Unable to download PDF',
  'Test Successful',
  'You have successfully completed the test ...',
  'Your Access Has Expired',
  'Successfully Purchased Access',
  'Provide feedback',
  'Save for later',
  'Download on the App Store',
  'New chat message',
  'Reschedule',
  'Program Type',
  'Reschedule Session',
  'Cancel Session',
  'Need to make changes or cancel your session?',
  'Team video meetings are used to...',
  'You are currently enrolled in this experience.',
  'Begin',
  'Resume',
  'Return',
  'Your coach',
  'Upcoming coaching sessions',
  'Access Days Remaining',
  'Session history',
  'view profile',
  'No coaches selected',
  'Notice: no upcoming sessions scheduled!',
  'As a reminder, you have been individually selected to have access to Leadership Coaching. Please schedule your next session as soon as possible.',
  'Pick up where you left off with your experience:',
  'Welcome Workday learners! Enroll in an Experience below to begin. When you complete your Experience, your status will be automatically reported to Workday.',
  'MyNotes.pdf',
  'Select Coach',
  'Coach Selected',
  'Coaching Style',
  'Leadership Expertise',
  'read more',
  'Introductory Video',
  'Select this Coach',
  'Unable to redirect back to the application.',
  'Unable to connect to the ExecOnline platform.',
  'Signing you in...',
  'The login request did not specify a valid application.',
  'Unable to establish a session for this application.',
  'Reach your full potential, on your schedule',
  'Leadership Experience',
  'Community video meetings are used to...',
  'Please search or select a program / name',
  'You do not belong to any programs.',
  'You are currently not on a community',
  'Your Community page will include a listing of participants on your ...',
  'Community link is unavailable',
  'Community',
  'Program ID is not present',
  'You are not a member of this program.',
  'Program not found',
  "You don't belong to any programs.",
  'Program Members',
  'Online Now',
  'The list below shows your peers who are currently in team messaging.',
  'The list below shows your peers who are currently in community messaging.',
  'Load more participants',
  'Could not identify a program',
  'Current Users',
  'Communities',
  'Get ready to grow with ExecOnline',
  '{{orgName}} is partnering with ExecOnline to provide highly actionable ...',
  'You have unsubscribed from...',
  'Unsubscribe link is unavailable',
  'Continue with your corporate...',
  'Set your ExecOnline password...',
  'Enter your email to continue.',
  'Enter your name and accept our Terms of Use...',
  'Continue to launch your corporate ...',
  'Processing Your Payment',
  'Something went wrong',
  'Your payment was declined by the credit card provider. Please try submitting payment again using a different credit card, or contact support@execonline.com for assistance.',
  'You are purchasing access to this experience.',
  'Add a Credit / Debit Card',
  'Credit / Debit Card',
  'In-app purchases are not supported',
  'Subtotal',
  'Taxes',
  'Included',
  'Total',
  'How would you like to pay?',
  'Continue Enrollment',
  'Payment Not Confirmed',
  'Something went wrong. Please contact support@execonline.com for assistance',
  'Please try again; we did not recognize your email address and/or password.',
  'Auditor',
  'Email or Username',
  'email@domain.com; username@id',
  'Enter your email address or username to continue.',
  'Provide Feedback',
  'Enterprise Portal',
  'Switch to',
  'Platform Navigation Dropdown Menu',
  'No Preference',
  'Salutation',
  'Primary Time Zone',
  'Mobile Phone',
  'Mr.',
  'Ms.',
  'Dr.',
  'Mx.',
  'Prefer not to answer',
  'Select from list',
  'Not a valid salutation',
  'Key Benefits',
  'How to Best Use Your Coaching',
  "Welcome to ExecOnline's Leadership Coaching! Your experience includes the following components.",
  'Get Started',
  'Get Started Today',
  'Ready to take the next steps...',
  'Get Coaching',
  'Time Investment',
  'Message other participants',
  'Flexible',
  'Not Available in Preview Mode',
  "You are previewing a Learning Collection. The 'Enroll' and 'Learn More' features are not available in preview mode.",
  'Learning',
  'AdminStudio',
  'Competency',
  'Filtering competencies failed.',
  'change coach',
  'Graduated',
  'Course Completion Date',
  'Graduation Date',
  'Offered by',
  'Competencies',
  'Please select a salutation from the list',
  'You are not permitted to unsubscribe from this email',
  'Unable to unsubscribe. Course Registration with the requested ID could not be found.',
  'Back to all programs',
  'Upcoming session',
  'Theme Details',
  'Your Team',
  'Coach not set',
  'No further group coaching sessions scheduled',
  'No coach set for further sessions',
  'Invited',
  'See more sessions',
  'Lead by:',
  'No availability',
  'Coach Photo',
  'Leadership Coaching experiences can address...',
  'Choose Your Start Date',
  'Included Sessions',
  'Explore the sessions in this group coaching package to...',
  'ExecOnline will send you calendar invitations for the sessions...',
  'You must have an active license to schedule sessions with your coach',
  'Your Account has been locked...',
  'Resources - Select Program',
  'Resources - By Programs',
  'Resources - Needed For Assignments',
  'Resources - Personalized To You',
  'Resources - Live Meeting Recordings',
  'Resources - Uploaded By You',
  'Resources - Shared By A Learning Partner',
  'The recommended completion time period has passed. Please continue ...',
  'Leadership Coaching',
  'Enrolled',
  'On-Demand Program',
  'Scheduled Program',
  'Develop these Competencies',
  'All Programs in this Catalog',
  'Return to Program',
  'You are already enrolled.',
  'Upcoming Start Dates',
  'Unlimited sessions',
  'Leadership coaching addresses all competencies in the ExecOnline catalog in addition to the individualized development needs of the participant.',
  'Group Coaching',
  'You graduated from this program',
  'Download',
  'Session not scheduled yet',
  'Multi Step Learning Journey',
  'You are currently enrolled in a program',
  'Earlier options may not be available due to conflicts with other enrollments',
  'Tax',
  'Order Details',
  'Back to Payment Method',
  'If Leadership Coaching is also available to you and you have not enrolled yet, you can do so from the Learning Collection at any time...',
  'Student Testimonial',
  'How to Best use your coaching',
  'Who should attend',
  'Competencies you will gain',
  'What you will learn',
  'Download Program Overview',
  'Once you elect to enroll in this group coaching package, you will be sent...',
  'Led by:',
  'Search by Experience',
  'Search Results',
  'Leadership Coaching is available to you for the duration of your license.',
  'Partner',
  'Filters',
  'Finish',
  'Clear All',
  'Apply',
  'Search in this collection',
  'As part of this learning journey you will complete programs from the following partners.',
  'Your Learning Journey',
  'Grow with leadership coaching',
  'Your coach is ready to transform your leadership!',
  'Leadership coaching is available to you at any time during your license...',
] as const;

const notTranslatable = [
  '***********',
  'https://www.linkedin.com/in/johndoe',
  'https://www.facebook.com/johndoe',
  'https://www.twitter.com/johndoe',
] as const;

/*
 * Use `{{...}}` to interpolate scalar values (string | number).
 *   Use a `string` type for interpolation when injecting non-translatable content, such as proper nouns, or content that has already been translated externally (string).
 * Use `<abc>...</abc>` to wrap translated content in an element (Interpolator).
 * Use `<xyz/>` to replace with elements that don't need translation content in them (React.ReactElement).
 */
type TParameterizedProps =
  | { kind: 'Your Items for <date/>'; date: React.ReactElement }
  | {
      kind: 'Your event starts in <expectedDelay/> at <time/>. Please come back at that time.';
      time: React.ReactElement;
      expectedDelay: React.ReactElement;
    }
  | { kind: '{{count}} Day Remaining in Module'; count: number }
  | { kind: 'Warning: your experience ended {{count}} day ago'; count: number }
  | { kind: 'Due {{count}} day ago'; count: number }
  | { kind: '({{count}} day from now)'; count: number }
  | { kind: '{{count}} minute'; count: number }
  | { kind: 'show {{count}} replies from'; count: number }
  | {
      kind: 'Showing {{currentCount}} of {{totalCount}} results';
      currentCount: number;
      totalCount: number;
    }
  | { kind: 'Video added on <date/>'; date: React.ReactElement }
  | { kind: 'Document added on <date/>'; date: React.ReactElement }
  | { kind: 'Your experience will start on <date/>.'; date: React.ReactElement }
  | { kind: 'This video was added on <date/>'; date: React.ReactElement }
  | { kind: 'Starts On <date/>'; date: React.ReactElement }
  | {
      kind: 'This program will be accessible on <date/>. Return then to take this course.';
      date: React.ReactElement;
    }
  | {
      kind: 'You may continue to schedule sessions with your coach until <dateSpan><date /></dateSpan>';
      date: React.ReactElement;
      dateSpan: Interpolator;
    }
  | {
      kind: '<sessionCount>{{count}} session(s)</sessionCount> completed with your coach so far';
      sessionCount: Interpolator;
      count: number;
    }
  | {
      kind: 'You have completed total of <sessionCount>{{count}} session(s)</sessionCount> with your coach';
      sessionCount: Interpolator;
      count: number;
    }
  | {
      kind: '<dayCount/> <text>day remaining</text>';
      dayCount: React.ReactElement;
      text: Interpolator;
      count: number;
    }
  | {
      kind: 'Access Until: You may schedule sessions with your coach until: <date />';
      date: React.ReactElement;
    }
  | {
      kind: 'Next Enrollment: <date/>';
      date: React.ReactElement;
    }
  | {
      kind: 'Experience begins <date/>';
      date: React.ReactElement;
    }
  | {
      kind: 'You completed this experience on this date: <graduationDate/>.';
      graduationDate: React.ReactElement;
    }
  | {
      kind: 'If you need additional help, you can ...';
      emailAddress: React.ReactElement;
    }
  | {
      kind: 'If you need additional help, you can <link>view ...';
      link: Interpolator;
      emailAddress: React.ReactElement;
    }
  | { kind: 'Impersonating by {{emailAddress}}'; emailAddress: string }
  | {
      kind: '<fragment>added a document on</fragment> <date/>';
      fragment: Interpolator;
      date: React.ReactElement;
    }
  | {
      kind: 'If you are calling from outside of the United States ...';
      link: Interpolator;
    }
  | {
      kind: 'All discussions can only be seen by your team ...';
      link: Interpolator;
    }
  | {
      kind: 'All discussions can only be seen by your community ...';
      link: Interpolator;
    }
  | {
      kind: 'All discussions can only be seen by program members ...';
      link: Interpolator;
    }
  | {
      kind: 'Need help or have a question? Contact ...';
      emailAddress: React.ReactElement;
    }
  | {
      kind: 'If you do not see the Explore link you should ...';
      link: Interpolator;
    }
  | { kind: '{{count}} resource'; count: number }
  | { kind: 'Program starts: <date/>'; date: React.ReactElement }
  | { kind: 'Program starts <date/>'; date: React.ReactElement }
  | {
      kind: 'Daily Dial In: <phoneNumber>US +1 (209) 503-8039 / CA +1 (226) 807-8039</phoneNumber>';
      phoneNumber: Interpolator;
    }
  | {
      kind: 'Your cohort is made up of the global community ...';
      name: React.ReactElement;
    }
  | {
      kind: '<time/> <fragment>remaining</fragment>';
      time: React.ReactElement;
      fragment: Interpolator;
    }
  | { kind: 'Retrying in {{count}} second'; count: number }
  | { kind: 'A new resource, {{title}}, has been shared with you.'; title: string }
  | { kind: 'You will automatically be logged out in {{count}} second'; count: number }
  | { kind: '{{title}} at <time/>.'; title: string; time: React.ReactElement }
  | { kind: 'Choose <fragment>Day</fragment> and Time'; fragment: Interpolator }
  | {
      kind: 'Questions? Email us at <emailAddress/>.';
      emailAddress: React.ReactElement;
    }
  | { kind: 'View {{title}} Files'; title: string }
  | { kind: 'You should be in {{name}} by <date/>'; name: string; date: React.ReactElement }
  | { kind: '({{count}} day remaining)'; count: number }
  | { kind: '{{count}} participant has their hand raised'; count: number }
  | { kind: 'You have {{count}} new message'; count: number }
  | {
      kind: 'Use of this site constitutes acceptance of ...';
      link1: Interpolator;
      link2: Interpolator;
    }
  | {
      kind: '<link1>Sitemap</link1>.';
      link1: Interpolator;
    }
  | {
      kind: 'Please continue to your corporate single sign-on site to log in. To bypass ...';
      link1: Interpolator;
    }
  | { kind: 'Instructor'; count: number }
  | { kind: 'File: {{count}}'; count: number }
  | { kind: '{{count}} event'; count: number }
  | { kind: 'You have {{count}} module remaining'; count: number }
  | { kind: 'You have {{count}} step remaining'; count: number }
  | { kind: 'Current Module Due on <date/>'; date: React.ReactElement }
  | { kind: 'Filename: {{name}}'; name: string }
  | { kind: 'Developer error: <message/>'; message: React.ReactElement }
  | { kind: 'Team'; count: number }
  | { kind: '{{count}} minute from now'; count: number }
  | { kind: '{{count}} minute ago'; count: number }
  | { kind: 'about {{count}} hour from now'; count: number }
  | { kind: 'about {{count}} hour ago'; count: number }
  | { kind: '{{count}} day from now'; count: number }
  | { kind: '{{count}} day ago'; count: number }
  | { kind: '{{count}} month from now'; count: number }
  | { kind: '{{count}} month ago'; count: number }
  | { kind: 'over {{count}} year from now'; count: number }
  | { kind: 'over {{count}} year ago'; count: number }
  | {
      kind: 'Please review your choices carefully before ...';
      emphasis: Interpolator;
    }
  | {
      kind: 'Shortly following submission, you will receive ...';
      emailAddress: React.ReactElement;
    }
  | {
      kind: 'Although you can run the analysis with as little ...';
      emphasis: Interpolator;
      surveyCount: React.ReactElement;
    }
  | {
      kind: 'As of now, only <surveyCount/> been returned.';
      surveyCount: React.ReactElement;
    }
  | { kind: 'Room: {{roomId}}'; roomId: string }
  | { kind: 'Pin: {{pin}}'; pin: string }
  | { kind: '{{count}} Program'; count: number }
  | { kind: 'Selected: ({{count}})'; count: number }
  | { kind: 'Learning Partner'; count: number }
  | { kind: 'Experience ends <date/>'; date: React.ReactElement }
  | { kind: '{{name}} added {{thing}}'; name: string; thing: string }
  | { kind: 'added {{thing}}'; thing: string }
  | {
      kind: '{{count}} module completed, {{remainingCount}} remaining';
      count: number;
      remainingCount: number;
    }
  | {
      kind: '{{count}} step completed, {{remainingCount}} remaining';
      count: number;
      remainingCount: number;
    }
  | {
      kind: 'I have read and agree to the terms ...';
      link1: Interpolator;
      link2: Interpolator;
    }
  | {
      kind: 'The {{title}} event is starting at <time/>.';
      title: string;
      time: React.ReactElement;
    }
  | {
      kind: 'The {{title}} event started at <time/>.';
      title: string;
      time: React.ReactElement;
    }
  | { kind: 'Now - <date/>'; date: React.ReactElement }
  | {
      kind: 'ExecOnline utilizes Daily technology for live video sessions. By ...';
      dtos: Interpolator;
      dpp: Interpolator;
      eotou: Interpolator;
      eopp: Interpolator;
    }
  | {
      kind: 'Use of this site constitutes acceptance of our <link1>Terms ...';
      link1: Interpolator;
      link2: Interpolator;
      link3: Interpolator;
    }
  | {
      kind: 'To cancel or reschedule your session please email ...';
      link1: Interpolator;
    }
  | { kind: 'Week'; count: number }
  | { kind: '{{count}} week'; count: number }
  | { kind: '{{count}} hour per week'; count: number }
  | { kind: '{{count}} total hour'; count: number }
  | { kind: '{{count}} Hour'; count: number }
  | { kind: '{{count}} Day'; count: number }
  | { kind: '{{count}} School'; count: number }
  | { kind: '{{count}} Language'; count: number }
  | {
      kind: 'Your {{organizationName}} account has been locked ...';
      organizationName: string;
    }
  | { kind: 'Current Month: {{date}}'; date: string }
  | { kind: 'Today, {{date}}'; date: string }
  | {
      kind: 'Welcome Degreed learners! Enroll in an Experience below to begin. When you complete your Experience, your status will be automatically reported to Degreed as a "Verified Completion." Check out our <link>Degreed Learner Guide</link> for a full overview.';
      link: Interpolator;
    }
  | {
      kind: 'I accept the <link1>terms of use</link1> and <link2>privacy policy</link2>';
      link1: Interpolator;
      link2: Interpolator;
    }
  | {
      kind: 'Having trouble accessing your account? Contact ...';
      emailAddress: React.ReactElement;
    }
  | {
      kind: 'If this email address is in our database you ...';
      emailAddress: React.ReactElement;
    }
  | {
      kind: 'Get ready to grow with {{orgName}}';
      orgName: string;
    }
  | {
      kind: '{{orgName}} is working with ExecOnline to provide access ...';
      orgName: string;
    }
  | {
      kind: '{{orgName}} is partnering with ExecOnline to provide highly actionable ...';
      orgName: string;
    }
  | {
      kind: 'Transaction number: {{id}}';
      id: string;
    }
  | {
      kind: 'You are currently enrolled in <enrollment/>';
      enrollment: string;
    }
  | {
      kind: 'This experience began <startOnDate/> and ends on <endOnDate/>. ';
      startOnDate: React.ReactElement;
      endOnDate: React.ReactElement;
    }
  | {
      kind: 'This experience starts on <startOnDate/> and ends on <endOnDate/>. ';
      startOnDate: React.ReactElement;
      endOnDate: React.ReactElement;
    }
  | {
      kind: 'This experience began <startOnDate/>. ';
      startOnDate: React.ReactElement;
    }
  | {
      kind: '<date1/>, <time1/> – <date2/>, <time2/>';
      date1: React.ReactElement;
      time1: React.ReactElement;
      date2: React.ReactElement;
      time2: React.ReactElement;
    }
  | {
      kind: '<startDate/>, <startTime/>';
      startDate: React.ReactElement;
      startTime: React.ReactElement;
    }
  | {
      kind: 'Pusher error: <message/>';
      message: React.ReactElement;
    }
  | {
      kind: '<link1>Privacy ...';
      link1: Interpolator;
    }
  | {
      kind: '<link1>Terms ...';
      link1: Interpolator;
    }
  | {
      kind: 'If you experience any errors during these tests ...';
      emailAddress: React.ReactElement;
    }
  | {
      kind: '<link1>Reset your password</link1>, then try...';
      link1: Interpolator;
      emailAddress: React.ReactElement;
    }
  | {
      kind: 'To re-enroll in this experience ...';
      emailAddress: React.ReactElement;
    }
  | {
      kind: 'Contact <link1>ExecOnline Support</link1> with any questions.';
      link1: Interpolator;
    }
  | {
      kind: 'Your card will be charged <price/>';
      price: React.ReactElement;
    }
  | {
      kind: 'The program will start <time/>';
      time: React.ReactElement;
    }
  | {
      kind: '{{title}} is designed to be completed in {{count}} week';
      title: string;
      count: number;
    }
  | {
      kind: 'Offered By <logo />';
      logo: React.ReactElement;
    }
  | {
      kind: '{{count}} Competency';
      count: number;
    }
  | { kind: 'Graduated from <span>{{date}}</span> cohort.'; date: string; span: Interpolator }
  | { kind: '{{count}} Section'; count: number }
  | { kind: '{{count}} School Partner'; count: number }
  | { kind: 'Only {{count}} seat remaining'; count: number }
  | { kind: '{{count}} session'; count: number }
  | { kind: '{{count}} minutes each'; count: number }
  | { kind: 'Enrolled Starts <date/>'; date: React.ReactElement }
  | { kind: 'This program starts on <date/>'; date: React.ReactElement }
  | { kind: 'You graduated from this program on <date/>'; date: React.ReactElement }
  | { kind: 'Available in <span>{{languages}}</span>'; languages: string; span: Interpolator }
  | { kind: 'You have {{count}} day left to complete this program'; count: number }
  | { kind: 'Yor have {{count}} day left to access this program'; count: number }
  | { kind: '{{count}} Date'; count: number }
  | { kind: '{{count}} min sessions'; count: number }
  | { kind: 'See +{{count}} more'; count: number }
  | { kind: '{{programTitle}} - My Action Plan.pdf'; programTitle: string }
  | {
      kind: "You can't enroll until you graduate from <strong>{{name}}</strong>";
      name: string;
      strong: Interpolator;
    }
  | {
      kind: 'Please do <strong>not</strong> close this dialog or your browser tab.';
      strong: Interpolator;
    }
  | { kind: '{{schoolName}} Certificate'; schoolName: string }
  | { kind: 'Search Results for "{{term}}" in {{useCase}}'; term: string; useCase: string }
  | {
      kind: 'Step {{current}}';
      current: number;
    }
  | {
      kind: 'of {{length}}';
      length: number;
    }
  | { kind: 'at {{count}} hour per week'; count: number }
  | { kind: 'Welcome {{username}}'; username: string };

export const parameterizedValues = (
  t: TParameterizedProps,
): Parameterized<TParameterizedKey, TParameterizedProps> => {
  switch (t.kind) {
    case 'Your Items for <date/>':
    case 'Video added on <date/>':
    case 'Document added on <date/>':
    case 'Your experience will start on <date/>.':
    case 'This video was added on <date/>':
    case 'Program starts: <date/>':
    case 'Program starts <date/>':
    case 'Now - <date/>':
    case 'This program will be accessible on <date/>. Return then to take this course.':
    case 'Access Until: You may schedule sessions with your coach until: <date />':
    case 'Next Enrollment: <date/>':
    case 'Starts On <date/>':
    case 'Experience begins <date/>':
    case 'Enrolled Starts <date/>':
    case 'This program starts on <date/>':
    case 'You graduated from this program on <date/>':
      return p(t, { date: element(t.date) });
    case '{{count}} Hour':
    case '{{count}} Day':
    case '{{count}} Language':
    case '{{count}} Section':
    case '{{count}} School Partner':
    case '{{count}} School':
    case 'Warning: your experience ended {{count}} day ago':
    case 'Due {{count}} day ago':
    case '({{count}} day from now)':
    case '{{count}} Day Remaining in Module':
    case '{{count}} minute':
    case '{{count}} resource':
    case '{{count}} participant has their hand raised':
    case 'File: {{count}}':
    case '{{count}} event':
    case 'You have {{count}} module remaining':
    case 'You have {{count}} step remaining':
    case 'Retrying in {{count}} second':
    case 'You have {{count}} new message':
    case 'You will automatically be logged out in {{count}} second':
    case '{{count}} minute from now':
    case '{{count}} minutes each':
      return p(t, { count: scalar(t.count) });
    case '{{count}} minute ago':
    case 'about {{count}} hour from now':
      return p(t, { count: scalar(t.count) });
    case 'about {{count}} hour ago':
    case '{{count}} day from now':
      return p(t, { count: scalar(t.count) });
    case '{{count}} day ago':
    case '{{count}} month from now':
    case '{{count}} month ago':
    case 'over {{count}} year from now':
    case 'over {{count}} year ago':
    case 'Instructor':
    case 'Team':
    case 'Learning Partner':
    case '({{count}} day remaining)':
    case 'Week':
    case '{{count}} week':
    case '{{count}} hour per week':
    case '{{count}} total hour':
    case 'show {{count}} replies from':
    case '{{count}} Competency':
    case 'Only {{count}} seat remaining':
    case '{{count}} session':
    case 'You have {{count}} day left to complete this program':
    case 'Yor have {{count}} day left to access this program':
    case '{{count}} Date':
    case 'at {{count}} hour per week':
    case '{{count}} min sessions':
    case 'See +{{count}} more':
      return p(t, { count: scalar(t.count) });
    case 'You may continue to schedule sessions with your coach until <dateSpan><date /></dateSpan>':
      return p(t, { dateSpan: interpolator(t.dateSpan), date: element(t.date) });
    case '<sessionCount>{{count}} session(s)</sessionCount> completed with your coach so far':
    case 'You have completed total of <sessionCount>{{count}} session(s)</sessionCount> with your coach':
      return p(t, { sessionCount: interpolator(t.sessionCount), count: scalar(t.count) });
    case '<dayCount/> <text>day remaining</text>':
      return p(t, {
        dayCount: element(t.dayCount),
        text: interpolator(t.text),
        count: scalar(t.count),
      });
    case 'Showing {{currentCount}} of {{totalCount}} results':
      return p(t, { currentCount: scalar(t.currentCount), totalCount: scalar(t.totalCount) });
    case 'If you need additional help, you can ...':
    case 'Need help or have a question? Contact ...':
    case 'Questions? Email us at <emailAddress/>.':
    case 'Shortly following submission, you will receive ...':
    case 'Having trouble accessing your account? Contact ...':
    case 'If this email address is in our database you ...':
      return p(t, { emailAddress: element(t.emailAddress) });
    case 'If you need additional help, you can <link>view ...':
      return p(t, { link: interpolator(t.link), emailAddress: element(t.emailAddress) });
    case 'Impersonating by {{emailAddress}}':
      return p(t, { emailAddress: scalar(t.emailAddress) });
    case '<fragment>added a document on</fragment> <date/>':
      return p(t, { fragment: interpolator(t.fragment), date: element(t.date) });
    case 'If you are calling from outside of the United States ...':
    case 'All discussions can only be seen by your team ...':
    case 'All discussions can only be seen by your community ...':
    case 'All discussions can only be seen by program members ...':
    case 'If you do not see the Explore link you should ...':
      return p(t, { link: interpolator(t.link) });
    case 'Choose <fragment>Day</fragment> and Time':
      return p(t, { fragment: interpolator(t.fragment) });
    case 'Daily Dial In: <phoneNumber>US +1 (209) 503-8039 / CA +1 (226) 807-8039</phoneNumber>':
      return p(t, { phoneNumber: interpolator(t.phoneNumber) });
    case 'Your cohort is made up of the global community ...':
      return p(t, { name: element(t.name) });
    case '<time/> <fragment>remaining</fragment>':
      return p(t, { time: element(t.time), fragment: interpolator(t.fragment) });
    case 'A new resource, {{title}}, has been shared with you.':
    case 'View {{title}} Files':
      return p(t, { title: scalar(t.title) });
    case '{{title}} at <time/>.':
      return p(t, { title: scalar(t.title), time: element(t.time) });
    case 'You should be in {{name}} by <date/>':
      return p(t, { name: scalar(t.name), date: element(t.date) });
    case 'Use of this site constitutes acceptance of ...':
    case 'I have read and agree to the terms ...':
    case 'I accept the <link1>terms of use</link1> and <link2>privacy policy</link2>':
      return p(t, { link1: interpolator(t.link1), link2: interpolator(t.link2) });
    case '<link1>Sitemap</link1>.':
    case 'Please continue to your corporate single sign-on site to log in. To bypass ...':
    case 'To cancel or reschedule your session please email ...':
      return p(t, { link1: interpolator(t.link1) });
    case 'Current Module Due on <date/>':
    case 'Experience ends <date/>':
      return p(t, { date: element(t.date) });
    case 'Today, {{date}}':
    case 'Current Month: {{date}}':
      return p(t, { date: scalar(t.date) });
    case 'Selected: ({{count}})':
    case '{{count}} Program':
      return p(t, { count: scalar(t.count) });
    case 'Filename: {{name}}':
      return p(t, { name: scalar(t.name) });
    case 'Developer error: <message/>':
      return p(t, { message: element(t.message) });
    case 'Please review your choices carefully before ...':
      return p(t, { emphasis: interpolator(t.emphasis) });
    case 'Although you can run the analysis with as little ...':
      return p(t, { emphasis: interpolator(t.emphasis), surveyCount: element(t.surveyCount) });
    case 'As of now, only <surveyCount/> been returned.':
      return p(t, { surveyCount: element(t.surveyCount) });
    case 'Room: {{roomId}}':
      return p(t, { roomId: scalar(t.roomId) });
    case 'Pin: {{pin}}':
      return p(t, { pin: scalar(t.pin) });
    case '{{name}} added {{thing}}':
      return p(t, { name: scalar(t.name), thing: scalar(t.thing) });
    case 'added {{thing}}':
      return p(t, { thing: scalar(t.thing) });
    case '{{count}} module completed, {{remainingCount}} remaining':
    case '{{count}} step completed, {{remainingCount}} remaining':
      return p(t, { count: scalar(t.count), remainingCount: scalar(t.remainingCount) });
    case 'The {{title}} event is starting at <time/>.':
    case 'The {{title}} event started at <time/>.':
      return p(t, { title: scalar(t.title), time: element(t.time) });
    case 'ExecOnline utilizes Daily technology for live video sessions. By ...':
      return p(t, {
        dtos: interpolator(t.dtos),
        dpp: interpolator(t.dpp),
        eotou: interpolator(t.eotou),
        eopp: interpolator(t.eopp),
      });
    case 'Use of this site constitutes acceptance of our <link1>Terms ...':
      return p(t, {
        link1: interpolator(t.link1),
        link2: interpolator(t.link2),
        link3: interpolator(t.link3),
      });
    case 'Your {{organizationName}} account has been locked ...':
      return p(t, { organizationName: scalar(t.organizationName) });
    case 'Welcome Degreed learners! Enroll in an Experience below to begin. When you complete your Experience, your status will be automatically reported to Degreed as a "Verified Completion." Check out our <link>Degreed Learner Guide</link> for a full overview.':
      return p(t, { link: interpolator(t.link) });
    case '{{orgName}} is working with ExecOnline to provide access ...':
    case 'Get ready to grow with {{orgName}}':
    case '{{orgName}} is partnering with ExecOnline to provide highly actionable ...':
      return p(t, { orgName: scalar(t.orgName) });
    case 'You are currently enrolled in <enrollment/>':
      return p(t, { enrollment: scalar(t.enrollment) });
    case 'This experience began <startOnDate/> and ends on <endOnDate/>. ':
    case 'This experience starts on <startOnDate/> and ends on <endOnDate/>. ':
      return p(t, { startOnDate: element(t.startOnDate), endOnDate: element(t.endOnDate) });
    case 'This experience began <startOnDate/>. ':
      return p(t, { startOnDate: element(t.startOnDate) });
    case 'Transaction number: {{id}}':
      return p(t, { id: scalar(t.id) });
    case '<date1/>, <time1/> – <date2/>, <time2/>':
      return p(t, {
        date1: element(t.date1),
        time1: element(t.time1),
        date2: element(t.date2),
        time2: element(t.time2),
      });
    case '<startDate/>, <startTime/>':
      return p(t, {
        startDate: element(t.startDate),
        startTime: element(t.startTime),
      });
    case 'Your event starts in <expectedDelay/> at <time/>. Please come back at that time.':
      return p(t, { expectedDelay: element(t.expectedDelay), time: element(t.time) });
    case 'Pusher error: <message/>':
      return p(t, { message: element(t.message) });
    case '<link1>Privacy ...':
      return p(t, {
        link1: interpolator(t.link1),
      });
    case '<link1>Terms ...':
      return p(t, {
        link1: interpolator(t.link1),
      });
    case 'If you experience any errors during these tests ...':
      return p(t, { emailAddress: element(t.emailAddress) });
    case '<link1>Reset your password</link1>, then try...':
      return p(t, {
        link1: interpolator(t.link1),
        emailAddress: element(t.emailAddress),
      });
    case 'To re-enroll in this experience ...':
      return p(t, { emailAddress: element(t.emailAddress) });
    case 'You completed this experience on this date: <graduationDate/>.':
      return p(t, { graduationDate: element(t.graduationDate) });
    case 'Contact <link1>ExecOnline Support</link1> with any questions.':
      return p(t, { link1: interpolator(t.link1) });
    case 'Your card will be charged <price/>':
      return p(t, { price: element(t.price) });
    case 'The program will start <time/>':
      return p(t, { time: element(t.time) });
    case '{{title}} is designed to be completed in {{count}} week':
      return p(t, { title: scalar(t.title), count: scalar(t.count) });
    case 'Offered By <logo />':
      return p(t, {
        logo: element(t.logo),
      });
    case 'Graduated from <span>{{date}}</span> cohort.':
      return p(t, { date: scalar(t.date), span: interpolator(t.span) });
    case '{{programTitle}} - My Action Plan.pdf':
      return p(t, { programTitle: scalar(t.programTitle) });
    case "You can't enroll until you graduate from <strong>{{name}}</strong>":
      return p(t, { name: scalar(t.name), strong: interpolator(t.strong) });
    case 'Please do <strong>not</strong> close this dialog or your browser tab.':
      return p(t, { strong: interpolator(t.strong) });
    case 'Available in <span>{{languages}}</span>':
      return p(t, { span: interpolator(t.span), languages: scalar(t.languages) });
    case '{{schoolName}} Certificate':
      return p(t, { schoolName: scalar(t.schoolName) });
    case 'Search Results for "{{term}}" in {{useCase}}':
      return p(t, { term: scalar(t.term), useCase: scalar(t.useCase) });
    case 'Step {{current}}':
      return p(t, {
        current: scalar(t.current),
      });
    case 'of {{length}}':
      return p(t, {
        length: scalar(t.length),
      });
    case 'Welcome {{username}}':
      return p(t, { username: scalar(t.username) });
  }
};

export type TParameterizedKey = TParameterizedProps['kind'];
type TNotTranslatable = (typeof notTranslatable)[number];
type TTranslatablePlainTextKey = (typeof translatablePlainTextKeys)[number];

export type TPlainTextKey = TTranslatablePlainTextKey | TNotTranslatable;
export type TProps = Props<TPlainTextKey, TParameterizedKey, TParameterizedProps>;

export const whenTPlainTextKey = (candidate: string): Maybe<TPlainTextKey> => {
  const whenStringIncludedIn = find<TPlainTextKey>(equalTo(candidate));

  return whenStringIncludedIn(notTranslatable).orElse(() =>
    whenStringIncludedIn(translatablePlainTextKeys),
  );
};

export const { L, translation, translator, T } = translations<
  TPlainTextKey,
  TNotTranslatable,
  TParameterizedKey,
  TParameterizedProps
>(translatablePlainTextKeys, notTranslatable, parameterizedValues);

export function t9n(ts: TranslationsState) {
  return (
    text: TPlainTextKey | TParameterizedKey,
    interpolation?: Omit<TParameterizedProps, 'kind'>,
  ) => translation(text, interpolation)(ts);
}

export {
  AlreadyTranslated,
  alreadyTranslatedText,
  NotTranslated,
  TranslationsContext,
  TranslationsLoader,
} from '@execonline-inc/translations';
export type {
  AlreadyTranslatedText,
  LocalizationFormat,
  TranslationsState,
} from '@execonline-inc/translations';
export { localizer } from '@execonline-inc/translations/lib/localizations';

export function localize(ts: TranslationsState) {
  return (localizeable: Localizeable, format: LocalizationFormat) =>
    localizer(localizeable, format)(ts);
}

export interface NotYetTranslatedText {
  kind: 'not-yet-translated-text';
  translationKey: TPlainTextKey;
}

export const notYetTranslatedText = (translationKey: TPlainTextKey): NotYetTranslatedText => ({
  kind: 'not-yet-translated-text',
  translationKey,
});
