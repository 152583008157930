import { emptyFragment } from '@execonline-inc/execonline-ui';
import { AlreadyTranslated } from '@execonline-inc/translations';
import { fromEmpty, Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { useCallback, useMemo } from 'react';
import { ProductCollection } from '../../../../../Common/Experience/Types';
import DiscoveryContext from '../../../../../DiscoveryContext';
import {
  ExperienceSection,
  ExperienceSectionBody,
  ExperienceSectionDescription,
  ExperienceSectionHeader,
} from '../../../../Common/ExperienceSection';
import ExperienceUpdateFocus from '../../../../Common/ExperienceUpdateFocus';
import EnrollmentStore from '../../../../Enrollment/Store';
import CompetencyFilterStore from '../../../Filters/CompetencyFilter/Store';
import DurationFilterStore from '../../../Filters/DurationFilter/Store';
import FilterStore from '../../../Filters/FilterStore';
import LanguageFilterStore from '../../../Filters/LanguageFilter/Store';
import SchoolPartnerFilterStore from '../../../Filters/SchoolPartnerFilter/Store';
import NoExperiences from '../Common/NoExperiences';
import ProductCollectionDisplay from '../ProductCollections/ProductCollectionDisplay';
import TileCarouselExperiences from './TileCarouselExperiences';

interface Props {
  productCollection: ProductCollection;
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
  filterStore: FilterStore;
  languageFilterStore: LanguageFilterStore;
  durationFilterStore: DurationFilterStore;
  schoolPartnerFilterStore: SchoolPartnerFilterStore;
  competencyFilterStore: CompetencyFilterStore;
  searchValue: Maybe<string>;
}

function TileViewCarouselExperiences({
  productCollection,
  invitationUuid,
  enrollmentStore,
  filterStore,
  languageFilterStore,
  durationFilterStore,
  schoolPartnerFilterStore,
  competencyFilterStore,
  searchValue,
}: Props) {
  const filterExperiences = useCallback(
    (productCollection: ProductCollection): ProductCollection => {
      const experiences = productCollection.experiences.filter((experience) =>
        filterStore.showFilteredExperience(
          experience,
          languageFilterStore,
          durationFilterStore,
          schoolPartnerFilterStore,
          competencyFilterStore,
        ),
      );

      return { ...productCollection, experiences };
    },
    [
      competencyFilterStore.appliedValues,
      durationFilterStore.appliedValues,
      schoolPartnerFilterStore.appliedValues,
      languageFilterStore.appliedValues,
    ],
  );

  const filteredProductCollection = useMemo(() => {
    return filterExperiences(productCollection);
  }, [filterExperiences, productCollection]);

  return (
    <DiscoveryContext.Provider
      value={{
        kind: filteredProductCollection.kind,
        productCollectionId: filteredProductCollection.id,
      }}
    >
      <ExperienceSection>
        <ExperienceSectionHeader>
          <ExperienceUpdateFocus
            productCollection={filteredProductCollection}
            scopeType="product-collection"
          />
          {filteredProductCollection.description
            .map((description) => (
              <ExperienceSectionDescription>
                <AlreadyTranslated content={description} />
              </ExperienceSectionDescription>
            ))
            .getOrElse(emptyFragment)}
        </ExperienceSectionHeader>
        <ExperienceSectionBody>
          <ProductCollectionDisplay productCollection={filteredProductCollection}>
            {fromEmpty(filteredProductCollection.experiences)
              .map(() => (
                <TileCarouselExperiences
                  invitationUuid={invitationUuid}
                  enrollmentStore={enrollmentStore}
                  productCollection={filteredProductCollection}
                />
              ))
              .getOrElse(() => (
                <NoExperiences searchValue={searchValue} />
              ))}
          </ProductCollectionDisplay>
        </ExperienceSectionBody>
      </ExperienceSection>
    </DiscoveryContext.Provider>
  );
}

export default observer(TileViewCarouselExperiences);
