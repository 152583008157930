import { observer } from 'mobx-react';
import { NonEmptyList } from 'nonempty-list';
import { UseCaseExploreResource } from '../../../../DiscoveryStore/Types';
import UseCaseDropdownMenu from './UseCaseDropdownMenu';

interface Props {
  items: NonEmptyList<UseCaseExploreResource>;
}

function UseCaseNavigation({ items }: Props) {
  return <UseCaseDropdownMenu items={items} />;
}

export default observer(UseCaseNavigation);
