import { useConst } from '@execonline-inc/react-hooks.private';
import { observer } from 'mobx-react';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import AutoLaunchableModal from '../AutoLaunchableModal';
import AutoLaunchableModalStore from '../AutoLaunchableModal/AutoLaunchableModalStore';
import AutoLaunchableModalReactions from '../AutoLaunchableModal/AutoLaunchableModalStore/AutoLaunchableModalReactions';
import { AutoLaunchableContext } from './AutoLaunchableContext';
import { AutoLaunchableStore } from './AutoLaunchableStore';
import AutoLaunchableReactions from './AutoLaunchableStore/AutoLaunchableReactions';

function AutoLaunchable() {
  const autoLaunchableModalStore = useConst(() => new AutoLaunchableModalStore());
  const autoLaunchableStore = useConst(() => new AutoLaunchableStore());

  return (
    <WithCurrentUser
      children={(currentUserResource) => (
        <>
          <AutoLaunchableModal
            autoLaunchableModalStore={autoLaunchableModalStore}
            autoLaunchableStore={autoLaunchableStore}
          />
          <AutoLaunchableContext.Consumer>
            {(autoLaunchableMode) => (
              <AutoLaunchableReactions
                store={autoLaunchableStore}
                currentUserResource={currentUserResource}
                autoLaunchableMode={autoLaunchableMode}
                fireImmediately
              />
            )}
          </AutoLaunchableContext.Consumer>
          <AutoLaunchableModalReactions store={autoLaunchableModalStore} fireImmediately={true} />
        </>
      )}
    />
  );
}

export default observer(AutoLaunchable);
