import { observer } from 'mobx-react';
import React from 'react';
import { Button } from '@execonline-inc/execonline-ui';
import { NotTranslated } from '../../../../../../../../Translations';

interface Props {
  displayButtonLabel: string;
  onShowExperiences: () => void;
}

const ExpandButton: React.FC<Props> = ({ displayButtonLabel, onShowExperiences }) => {
  return (
    <div className="flex justify-center py-7">
      <Button color="primary" size="lg" onClick={onShowExperiences}>
        <NotTranslated text={displayButtonLabel} />
      </Button>
    </div>
  );
};

export default observer(ExpandButton);
