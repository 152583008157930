import { warnAndNotify } from '@execonline-inc/error-reporting.private';
import { assertNever } from '@kofno/piper';
import { succeed } from 'jsonous';
import AutoLaunchableModalStore from '.';
import { callApi } from '../../../Appy';
import ReactionComponent, { RCProps } from '../../../ReactionComponent';
import { State } from './Types';

interface Props extends RCProps<AutoLaunchableModalStore> {}

class AutoLaunchableModalReactions extends ReactionComponent<
  AutoLaunchableModalStore,
  State,
  Props
> {
  tester = () => this.props.store.state;

  effect = (state: State) => {
    switch (state.kind) {
      case 'closed':
        break;
      case 'open':
        break;
      case 'postponing':
        callApi(
          succeed({}),
          {},
        )(state.link).fork((e) => {
          warnAndNotify(
            'AutoLaunchableModalReactions',
            'Failed to update participant-postponables',
            e,
          );
          this.props.store.close();
        }, this.props.store.close);
        break;
      default:
        assertNever(state);
    }
  };
}

export default AutoLaunchableModalReactions;
