import { observer } from 'mobx-react';
import { DiscoveryStateProvider } from '../Common';
import UseCaseNavigation from './UseCaseNavigation';

function UseCaseMenu() {
  return (
    <DiscoveryStateProvider>
      {({ items }): React.ReactElement => {
        return <UseCaseNavigation items={items} />;
      }}
    </DiscoveryStateProvider>
  );
}

export default observer(UseCaseMenu);
