import React from 'react';
import { observer } from 'mobx-react';
import { NonEmptyList } from 'nonempty-list';
import { ProductCollection } from '../../../../../../Common/Experience/Types';
import EnrollmentStore from '../../../../../Enrollment/Store';
import DurationFilterStore from '../../../../Filters/DurationFilter/Store';
import FilterStore from '../../../../Filters/FilterStore';
import LanguageFilterStore from '../../../../Filters/LanguageFilter/Store';
import SchoolPartnerFilterStore from '../../../../Filters/SchoolPartnerFilter/Store';
import CompetencyFilterStore from '../../../../Filters/CompetencyFilter/Store';
import ProductCollectionDisplayType from '../ProductCollectionDisplayType';
import { Maybe } from 'maybeasy';

interface Props {
  productCollectionsList: NonEmptyList<ProductCollection>;
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
  filterStore: FilterStore;
  languageFilterStore: LanguageFilterStore;
  durationFilterStore: DurationFilterStore;
  schoolPartnerFilterStore: SchoolPartnerFilterStore;
  competencyFilterStore: CompetencyFilterStore;
  searchValue: Maybe<string>;
}

function ProductCollectionsSection({
  productCollectionsList,
  invitationUuid,
  enrollmentStore,
  filterStore,
  languageFilterStore,
  durationFilterStore,
  schoolPartnerFilterStore,
  competencyFilterStore,
  searchValue,
}: Props) {
  return (
    <div className="flex flex-grow flex-col gap-y-8">
      {productCollectionsList.map((productCollection) => (
        <React.Fragment key={productCollection.id}>
          <div className="bg-white shadow-section">
            <ProductCollectionDisplayType
              experienceResources={productCollection.experiences}
              productCollection={productCollection}
              invitationUuid={invitationUuid}
              enrollmentStore={enrollmentStore}
              filterStore={filterStore}
              languageFilterStore={languageFilterStore}
              durationFilterStore={durationFilterStore}
              schoolPartnerFilterStore={schoolPartnerFilterStore}
              competencyFilterStore={competencyFilterStore}
              searchValue={searchValue}
            />
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}

export default observer(ProductCollectionsSection);
