import { observer } from 'mobx-react';
import * as React from 'react';
import {
  EyeSlashIcon,
  EyeIcon,
  TextField,
  IconButton,
  TextFieldProps,
} from '@execonline-inc/execonline-ui';

interface Props extends TextFieldProps {}

function PasswordInputField({ ...others }: Props) {
  const [visible, setVisible] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setVisible(!visible);
  };

  return (
    <div className="relative flex flex-col sm:w-full">
      <TextField
        type={visible ? 'text' : 'password'}
        {...others}
        endContent={
          <div className="border-l border-solid border-gray-300">
            <IconButton onClick={handleClick} className="h-10 pl-2 pr-1.5">
              {visible ? <EyeSlashIcon /> : <EyeIcon />}
            </IconButton>
          </div>
        }
      />
    </div>
  );
}

export default observer(PasswordInputField);
