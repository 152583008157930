import { ButtonProps } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import SegmentStore from '../../../../SegmentStore';
import { AdvanceButton } from '../../../Segment/SegmentNavigation/Common';

interface Props extends ButtonProps {
  store: SegmentStore;
}

function PreviousSegmentButton({ store, ...others }: Props) {
  switch (store.state.kind) {
    case 'ready':
    case 'processing-request':
    case 'auto-saving-form-fields':
    case 'schedule-session':
      return <AdvanceButton {...others} onClick={store.previousAdvance} />;
    case 'advancing':
    case 'advancing-to':
    case 'completing':
    case 'completing-and-advancing':
    case 'reporting-results':
    case 'submitting-and-advancing':
    case 'previous-advancing':
    case 'loaded':
    case 'loading':
    case 'error':
    case 'waiting':
      return <AdvanceButton {...others} disabled />;
  }
}

export default observer(PreviousSegmentButton);
