import { equals } from '@execonline-inc/collections';
import { ChevronLeftIcon, ChevronRightIcon, emptyFragment } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { observer } from 'mobx-react';
import ProgramStore from '../../../ProgramStore';
import SegmentStore from '../../../SegmentStore';
import { T } from '../../../Translations';
import NextAdvanceButton from './NextAdvanceButton';
import PreviousAdvanceButton from './PreviousAdvanceButton';

interface Props {
  store: SegmentStore;
  programStore: ProgramStore;
}

function SegmentNavigation({ store, programStore }: Props) {
  return programStore.progress
    .map((progress) => {
      switch (progress.kind) {
        case 'program-progress':
        case 'module-progress':
          return (
            <div className="flex flex-1 justify-between">
              {programStore.programNavigationType
                .andThen((value) => when(equals('navigable'), value))
                .map(() => (
                  <PreviousAdvanceButton
                    data-testid="previous-segment-button"
                    store={store}
                    startContent={<ChevronLeftIcon color="inherit" size="sm" />}
                  >
                    <T kind="Previous Segment" />
                  </PreviousAdvanceButton>
                ))
                .getOrElse(emptyFragment)}
              <NextAdvanceButton
                data-testid="next-segment-button"
                className="ml-auto"
                store={store}
                endContent={<ChevronRightIcon color="inherit" size="sm" />}
              >
                <T kind="Next Segment" />
              </NextAdvanceButton>
            </div>
          );
        case 'registration-segment-progress':
          return (
            <div className="flex flex-1">
              <NextAdvanceButton
                data-testid="next-step-button"
                className="ml-auto"
                store={store}
                endContent={<ChevronRightIcon color="inherit" size="sm" />}
              >
                <T kind="Next Step" />
              </NextAdvanceButton>
            </div>
          );
      }
    })
    .getOrElse(emptyFragment);
}

export default observer(SegmentNavigation);
