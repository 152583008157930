import { observer } from 'mobx-react';
import { ChevronRightIcon, ListItem, ListItemButton } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../Translations';

interface Props {
  onClick: () => void;
}

function LearningMenuItem({ onClick }: Props) {
  return (
    <ListItem className="p-0" onClick={onClick}>
      <ListItemButton className="justify-between">
        <T kind="Learning Collections" />
        <ChevronRightIcon size="sm" />
      </ListItemButton>
    </ListItem>
  );
}

export default observer(LearningMenuItem);
