import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Maybe } from 'maybeasy';
import { CircleSolidIcon, IconButton } from '@execonline-inc/execonline-ui';
import { NavDotPages } from '../CarouselStore/Types';
import { CarouselPage } from '../Types';

interface Props<T> {
  dots: Maybe<NavDotPages<T>>;
  onDotClick: (page: CarouselPage<T>) => void;
}

function NavigationDots<T>({ dots, onDotClick }: Props<T>) {
  function handleDotClick(page: CarouselPage<T>) {
    return () => {
      onDotClick(page);
    };
  }
  const className = 'h-4 w-4 rounded-full';

  return dots
    .map(({ succeedingPages, precedingPages }) => (
      <div className="mt-6 hidden w-full justify-center gap-3 xl:flex">
        {precedingPages.map((page, i) => (
          <IconButton key={`preceding-${i}`} className={className} onClick={handleDotClick(page)}>
            <CircleSolidIcon className="text-gray-100" size="xs" />
          </IconButton>
        ))}
        <IconButton className={clsx('pointer-events-none cursor-default', className)} tabIndex={-1}>
          <CircleSolidIcon className="text-gray-450" size="xs" />
        </IconButton>
        {succeedingPages.map((page, i) => (
          <IconButton key={`succeeding-${i}`} className={className} onClick={handleDotClick(page)}>
            <CircleSolidIcon className="text-gray-100" size="xs" />
          </IconButton>
        ))}
      </div>
    ))
    .getOrElse(() => <div className="h-[30px]"></div>);
}

export default observer(NavigationDots);
