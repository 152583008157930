import { observer } from 'mobx-react';
import * as React from 'react';
import { DeliveryChannel, ExperienceResource } from '../../Common/Experience/Types';
import DegreedContent from './DegreedContent';
import Store from './Store';
import WorkdayContent from './WorkdayContent';

interface Props {
  experienceResource: ExperienceResource;
  deliveryChannel: DeliveryChannel;
  store: Store;
}

const DeliveryChannelContent: React.FC<Props> = ({
  experienceResource,
  deliveryChannel,
  store,
}) => {
  switch (deliveryChannel) {
    case 'LMS (Degreed)':
      return <DegreedContent experienceResource={experienceResource} store={store} />;
    case 'LMS (Workday Learning)':
      return <WorkdayContent experienceResource={experienceResource} store={store} />;
    case 'LMS (Docebo)':
    case 'LMS (Edcast)':
    case 'LMS (Gloat)':
    case 'Email Campaign':
    case 'L&D Newsletter':
    case 'Testing':
    case 'Social Sharing':
    case 'Other':
      return <></>;
  }
};

export default observer(DeliveryChannelContent);
