import { AlreadyTranslatedText } from '@execonline-inc/translations';
import { includes } from '../../../../../Collections';
import { SupportedLanguageCode } from '../../../../../SupportedLanguages/Types';
import { ExperienceResource } from '../../../Common/Experience/Types';
import CommonFilterStore from './CommonFilter/Store';

export type Filter = {
  availableLanguages: ReadonlyArray<SupportedLanguageCode>;
  durations: ReadonlyArray<number>;
  schoolPartners: ReadonlyArray<AlreadyTranslatedText>;
  competencies: ReadonlyArray<AlreadyTranslatedText>;
};

export interface Waiting {
  kind: 'waiting';
  filter: Filter;
}

export interface Loading {
  kind: 'loading';
  filter: Filter;
}

export interface Updating {
  kind: 'updating';
  filter: Filter;
}

export interface Error {
  kind: 'error';
  filter: Filter;
  message: string;
}

export type State = Waiting | Loading | Updating | Error;

export const allBlank = (filter: Filter): State => ({
  kind: 'waiting',
  filter,
});

export const exclude =
  <T>(value: T) =>
  (list: ReadonlyArray<T>): T[] =>
    list.filter((item) => item !== value);

export const include =
  <T>(value: T) =>
  (list: ReadonlyArray<T>): T[] => [...list, value];

const getAppliedValues = (filter: CommonFilterStore) => filter.appliedValues;

export const whenAnyMatchingDuration = (
  experience: ExperienceResource,
  durationFilter: CommonFilterStore,
): boolean => {
  const selectedValues = getAppliedValues(durationFilter);

  const filteredBy = experience.payload['totalHours'];

  return filteredBy
    .map((d) => includes(String(d), selectedValues))
    .getOrElse(() => includes('0', selectedValues));
};

export const whenAnyMatchingAvailableLanguages = (
  experience: ExperienceResource,
  languageFilter: CommonFilterStore,
): boolean => {
  const availableLanguages = experience.payload.availableLanguages.payload;
  const selectedValues = getAppliedValues(languageFilter);

  return availableLanguages.some((l) => includes(l.payload.code, selectedValues));
};

export const whenAnyMatchingSchoolPartner = (
  experience: ExperienceResource,
  schoolPartnerFilter: CommonFilterStore,
): boolean => {
  const schoolPartner = experience.payload.schoolPartner;
  const selectedValues = getAppliedValues(schoolPartnerFilter);

  return includes(schoolPartner.payload.name.text, selectedValues);
};

export const whenAnyMatchingCompetency = (
  experience: ExperienceResource,
  competencyFilter: CommonFilterStore,
): boolean => {
  const experiencePrimaryCompetencies = experience.payload.primaryCompetencies;
  const selectedValues = getAppliedValues(competencyFilter);

  return (
    experiencePrimaryCompetencies.some((competency) =>
      includes(competency.payload.name.text, selectedValues),
    ) || !experiencePrimaryCompetencies.length
  );
};
