import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { PerPageCount } from '../../Types';
import {
  advance,
  carousel,
  CarouselPage,
  CarouselState,
  contents,
  currentPage,
  gotoPage,
  precedingPages,
  retreat,
  succeedingPages,
} from '../Types';
import { NavDotPages } from './Types';

class CarouselStore<T> {
  @observable
  private state: CarouselState<T>;

  constructor(items: ReadonlyArray<T>, perPageCount: PerPageCount) {
    this.state = {
      items,
      perPageCount,
      carousel: carousel(perPageCount, items),
    };
  }

  @action
  advance = (): void => {
    this.state = {
      ...this.state,
      carousel: advance(this.state.carousel),
    };
  };

  @action
  retreat = (): void => {
    this.state = {
      ...this.state,
      carousel: retreat(this.state.carousel),
    };
  };

  @action
  gotoPage = (page: CarouselPage<T>): void => {
    this.state = {
      ...this.state,
      carousel: gotoPage(this.state.carousel, page),
    };
  };

  @computed
  get currentPage(): Maybe<CarouselPage<T>> {
    const { carousel } = this.state;
    return currentPage(carousel);
  }

  @computed
  get precedingPages(): ReadonlyArray<CarouselPage<T>> {
    const { carousel } = this.state;
    return precedingPages(carousel);
  }

  @computed
  get succeedingPages(): ReadonlyArray<CarouselPage<T>> {
    const { carousel } = this.state;
    return succeedingPages(carousel);
  }

  @computed
  get navDots(): Maybe<NavDotPages<T>> {
    return just({})
      .assign('currentPage', this.currentPage)
      .assign('precedingPages', just(this.precedingPages))
      .assign('succeedingPages', just(this.succeedingPages))
      .andThen((dots) =>
        dots.succeedingPages.length > 0 || dots.precedingPages.length > 0 ? just(dots) : nothing(),
      );
  }

  @computed
  get contents(): ReadonlyArray<T> {
    const { carousel } = this.state;
    return contents(carousel);
  }

  @computed
  get perPageCount(): number {
    const { perPageCount } = this.state;

    return perPageCount;
  }

  @computed
  get totalCount(): number {
    const { items } = this.state;

    return items.length;
  }
}

export default CarouselStore;
