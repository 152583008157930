import { observer } from 'mobx-react';
import { DiscoveryStateProvider } from '../../../Common';
import LearningNavigation from './LearningNavigation';

export interface Props {
  onClick: () => void;
}

function LearningMenu({ onClick }: Props) {
  return (
    <DiscoveryStateProvider>
      {({ items }) => <LearningNavigation items={items} onClick={onClick} />}
    </DiscoveryStateProvider>
  );
}

export default observer(LearningMenu);
