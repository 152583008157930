import { observer } from 'mobx-react';
import { NonEmptyList } from 'nonempty-list';
import { UseCaseExploreResource } from '../../../../../../DiscoveryStore/Types';
import LearningMenuItem from './LearningMenuItem';

interface Props {
  items: NonEmptyList<UseCaseExploreResource>;
  onClick: () => void;
}

function LearningNavigation({ items, onClick }: Props) {
  return <LearningMenuItem onClick={onClick} />;
}

export default observer(LearningNavigation);
