import { warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import { Task } from 'taskarian';
import { callApi } from '../../../../../Appy';
import { handleError } from '../../../../../ErrorActionableReaction';
import { findLinkT } from '../../../../../LinkyLinky';
import ReactionComponent, { RCProps } from '../../../../../ReactionComponent';
import { Link } from '../../../../../Resource/Types';
import { dataLayerUpdate } from '../../../../../components/Tooling/GoogleTagManagerTooling/Loader';
import { LoadingError } from '../../../Common/Experience/Types';
import { RegisterResource } from '../../RegisterResourceStore/Types';
import { discoveryPortalResourceDecoder, experienceResourceFilterDecoder } from './Decoders';
import ExperiencesStore from './ExperiencesStore';
import { State } from './ExperiencesStore/Types';
import { toUrlT } from '@execonline-inc/url';

const getExperiences = callApi(discoveryPortalResourceDecoder, {});

const getFilterExperiences = callApi(experienceResourceFilterDecoder, {});

const handleLoadingError = (store: ExperiencesStore) => (error: LoadingError) => {
  switch (error.kind) {
    case 'missing-link-error':
    case 'invalid-url-error':
      store.error('Discovery portal link is unavailable');
      break;
    default:
      handleError(store, error);
  }
};

const processLink =
  (search: string) =>
  (link: Link): Task<LoadingError, Link> => {
    const url = new URL(link.href);
    url.searchParams.set('search_term', search);
    return toUrlT(url.href).map((url): Link => ({ ...link, href: url.href }));
  };

interface Props extends RCProps<ExperiencesStore> {
  registerResource: RegisterResource;
}

class ExperiencesReactions extends ReactionComponent<ExperiencesStore, State, Props> {
  tester = () => this.props.store.state;
  effect = (state: State): void => {
    const { store, registerResource } = this.props;

    switch (state.kind) {
      case 'waiting':
        store.loading(registerResource);
        break;
      case 'loading':
        Task.succeed<LoadingError, ReadonlyArray<Link>>(state.registerResource.links)
          .andThen(findLinkT('discovery-portal'))
          .andThen(getExperiences)
          .fork(handleLoadingError(store), store.ready);
        break;
      case 'experiences':
      case 'product-collections':
      case 'product-collections-and-experiences':
        const discoveryPortalPayload = state.resource.payload;
        dataLayerUpdate({
          event: 'discovery-portal-data',
          learningCollectionId: discoveryPortalPayload.useCase.id,
          learningCollectionName: discoveryPortalPayload.useCase.name.text,
          productLicenseId: discoveryPortalPayload.useCase.productLicenseId,
          productLicenseType: discoveryPortalPayload.useCase.productLicenseType,
          pageTitle: document.title,
        });
        break;
      case 'searching':
        Task.succeed<LoadingError, Link>(state.link)
          .andThen(processLink(state.search.getOrElseValue('')))
          .andThen(getFilterExperiences)
          .map((response) => ({
            ...state.resource,
            payload: { ...state.resource.payload, experiences: response.payload },
          }))
          .fork(handleLoadingError(store), (resource) =>
            store.readyWithSearch(resource, state.search),
          );
        break;
      case 'error':
        warn('Unable to load discovery portal experiences', state.message);
        break;
      default:
        assertNever(state);
    }
  };
}

export default observer(ExperiencesReactions);
