import { when } from '@execonline-inc/maybe-adapter';
import { observer } from 'mobx-react';
import { ReactElement, useState } from 'react';
import { ProductCollection } from '../../../../../../Common/Experience/Types';
import ExpandButton from '../ExpandButton';

interface Props {
  productCollection: ProductCollection;
  children: ReactElement;
}

function ProductCollectionDisplay({ productCollection, children }: Props) {
  const [expanded, setExpanded] = useState(false);

  return productCollection.displayButtonLabel
    .andThen(when(!expanded))
    .map((label) => (
      <ExpandButton displayButtonLabel={label} onShowExperiences={() => setExpanded(true)} />
    ))
    .getOrElse(() => <>{children}</>);
}

export default observer(ProductCollectionDisplay);
