import { observer } from 'mobx-react';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  allExperiences,
  dynamicRecommendation,
  learningDesign,
  none,
  recEngine,
} from '../../DiscoveryContext';
import ExperienceDetailView from '../../ExperienceDetailView';
import ExperienceSelection from '../ExperienceSelection';
import ExperiencesStore from '../ExperienceSelection/Experiences/ExperiencesStore';
import { RegisterResource } from '../RegisterResourceStore/Types';
import GoToProgram from './GoToProgram';
import EnrollmentStore from './Store';

interface Props {
  registerResource: RegisterResource;
  enrollmentStore: EnrollmentStore;
  experiencesStore: ExperiencesStore;
}

const Routes: React.FC<Props> = ({ registerResource, enrollmentStore, experiencesStore }) => (
  <Switch>
    <Route
      path="/preview/:id"
      exact={true}
      render={(props) => (
        <ExperienceSelection
          invitationUuid={props.match.params.uuid}
          enrollmentStore={enrollmentStore}
          experiencesStore={experiencesStore}
        />
      )}
    />
    <Route
      path="/register/:uuid"
      exact={true}
      render={(props) => (
        <ExperienceSelection
          invitationUuid={props.match.params.uuid}
          enrollmentStore={enrollmentStore}
          experiencesStore={experiencesStore}
        />
      )}
    />
    <Route
      path="/register/:uuid/experience/:experienceId/from/learningDesign/:productCollectionId"
      exact={true}
      render={(props) => (
        <ExperienceDetailView
          experienceId={props.match.params.experienceId}
          invitationUuid={props.match.params.uuid}
          registerResource={registerResource}
          enrollmentStore={enrollmentStore}
          experiencesStore={experiencesStore}
          discoveryReferenceState={learningDesign(props.match.params.productCollectionId)}
        />
      )}
    />
    <Route
      path="/register/:uuid/experience/:experienceId/from/rec/:productCollectionId"
      exact={true}
      render={(props) => (
        <ExperienceDetailView
          experienceId={props.match.params.experienceId}
          invitationUuid={props.match.params.uuid}
          registerResource={registerResource}
          enrollmentStore={enrollmentStore}
          experiencesStore={experiencesStore}
          discoveryReferenceState={recEngine(props.match.params.productCollectionId)}
        />
      )}
    />
    <Route
      path="/register/:uuid/experience/:experienceId/from/dynamicRecommendation/:productCollectionId"
      exact={true}
      render={(props) => (
        <ExperienceDetailView
          experienceId={props.match.params.experienceId}
          invitationUuid={props.match.params.uuid}
          registerResource={registerResource}
          enrollmentStore={enrollmentStore}
          experiencesStore={experiencesStore}
          discoveryReferenceState={dynamicRecommendation(props.match.params.productCollectionId)}
        />
      )}
    />
    <Route
      path="/register/:uuid/experience/:experienceId/from/all"
      exact={true}
      render={(props) => (
        <ExperienceDetailView
          experienceId={props.match.params.experienceId}
          invitationUuid={props.match.params.uuid}
          registerResource={registerResource}
          enrollmentStore={enrollmentStore}
          experiencesStore={experiencesStore}
          discoveryReferenceState={allExperiences()}
        />
      )}
    />
    <Route
      path="/register/:uuid/experience/:experienceId"
      exact={true}
      render={(props) => (
        <ExperienceDetailView
          experienceId={props.match.params.experienceId}
          invitationUuid={props.match.params.uuid}
          registerResource={registerResource}
          enrollmentStore={enrollmentStore}
          experiencesStore={experiencesStore}
          discoveryReferenceState={none()}
        />
      )}
    />
    <Route
      path="/register/:uuid/experience/:experienceId/now"
      exact={true}
      render={(props) => (
        <GoToProgram
          experienceId={props.match.params.experienceId}
          invitationUuid={props.match.params.uuid}
          registerResource={registerResource}
          enrollmentStore={enrollmentStore}
          experiencesStore={experiencesStore}
        />
      )}
    />
  </Switch>
);

export default observer(Routes);
