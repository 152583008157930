import React from 'react';
import { observer } from 'mobx-react';
import { Maybe, fromEmpty, nothing } from 'maybeasy';
import { ExperienceResources, UseCaseDisplayType } from '../../../../Common/Experience/Types';
import EnrollmentStore from '../../../Enrollment/Store';
import DurationFilterStore from '../../Filters/DurationFilter/Store';
import FilterStore from '../../Filters/FilterStore';
import LanguageFilterStore from '../../Filters/LanguageFilter/Store';
import SchoolPartnerFilterStore from '../../Filters/SchoolPartnerFilter/Store';
import CompetencyFilterStore from '../../Filters/CompetencyFilter/Store';
import NoExperiences from './Common/NoExperiences';
import ExperienceDisplayType from './ExperienceDisplayType';

interface Props {
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
  experiences: ExperienceResources;
  filterStore: FilterStore;
  languageFilterStore: LanguageFilterStore;
  durationFilterStore: DurationFilterStore;
  schoolPartnerFilterStore: SchoolPartnerFilterStore;
  competencyFilterStore: CompetencyFilterStore;
  displayType: UseCaseDisplayType;
  searchValue: Maybe<string>;
}

const Experiences: React.FC<Props> = ({
  enrollmentStore,
  invitationUuid,
  experiences,
  filterStore,
  languageFilterStore,
  durationFilterStore,
  schoolPartnerFilterStore,
  competencyFilterStore,
  displayType,
  searchValue,
}) => {
  const displayTypeWithSearch = searchValue
    .map<UseCaseDisplayType>(() => 'list')
    .getOrElseValue(displayType);

  return (
    <div>
      {fromEmpty(experiences)
        .map((experienceResources) => (
          <ExperienceDisplayType
            kind="experiences-only"
            displayType={displayTypeWithSearch}
            invitationUuid={invitationUuid}
            experienceResources={experienceResources}
            enrollmentStore={enrollmentStore}
            filterStore={filterStore}
            searchValue={searchValue}
            languageFilterStore={languageFilterStore}
            durationFilterStore={durationFilterStore}
            schoolPartnerFilterStore={schoolPartnerFilterStore}
            competencyFilterStore={competencyFilterStore}
            productCollection={nothing()}
          />
        ))
        .getOrElse(() => (
          <NoExperiences searchValue={searchValue} />
        ))}
    </div>
  );
};

export default observer(Experiences);
