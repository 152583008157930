import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { fromEmpty } from 'maybeasy';
import {
  AccordionBody,
  AccordionHeader,
  AccordionSection,
  CheckBox,
  emptyFragment,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { TPlainTextKey } from '../../../../../../Translations';
import FilterChipButton from './FilterChipButton';
import FilterTitle from './MenuFilterTitle';
import CommonFilterStore from './Store';
import { FilterOption, isFilterItemSelected } from './Types';

interface Props {
  store: CommonFilterStore;
  title: TPlainTextKey;
  filterOptions: FilterOption[];
}

function MenuFilter({ store, title, filterOptions }: Props) {
  const selectedValues = [...store.state.filter.selectedValues];

  useEffect(() => {
    return () => {
      store.resetSelectedValues();
    };
  }, []);

  const handleClean = (store: CommonFilterStore) => () => {
    store.appendSelectedValues([]);
  };

  const handleItemClick = (store: CommonFilterStore, value: string) => () => {
    const result = when(store.selectedValues.includes(value), true)
      .map(() => store.selectedValues.filter((item) => item !== value))
      .getOrElse(() => [...store.selectedValues, value]);

    store.appendSelectedValues(result);
  };

  return (
    <AccordionSection>
      <AccordionHeader
        className="px-2"
        endContent={fromEmpty(selectedValues)
          .map(() => (
            <FilterChipButton value={selectedValues.length} onClear={handleClean(store)} />
          ))
          .getOrElse(emptyFragment)}
      >
        <FilterTitle title={title} />
      </AccordionHeader>
      <AccordionBody className="mt-1 p-0">
        <List className="-mx-4 px-4">
          {filterOptions.map(({ value, label }) => {
            return (
              <ListItem key={value} className="p-0" onClick={handleItemClick(store, value)}>
                <ListItemButton className="px-2">
                  <CheckBox
                    size="sm"
                    checked={isFilterItemSelected(selectedValues, value)}
                    tabIndex={-1}
                  >
                    <Typography
                      className="whitespace-normal text-left text-black"
                      variant="bodyMedium"
                    >
                      {label}
                    </Typography>
                  </CheckBox>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </AccordionBody>
    </AccordionSection>
  );
}

export default observer(MenuFilter);
